import {
  GET_COMPANY_FILTER_FAIL,
  GET_COMPANY_FILTER_SUCCESS,
  GET_COMPANY_LOCATION_FILTER_FAIL,
  GET_COMPANY_LOCATION_FILTER_SUCCESS,
  GET_CUSTOMER_FILTER_FAIL,
  GET_CUSTOMER_FILTER_SUCCESS,
  GET_FINANCIAL_YEAR_FILTER_FAIL,
  GET_FINANCIAL_YEAR_FILTER_SUCCESS,
  GET_PROJECT_FILTER_FAIL,
  GET_PROJECT_FILTER_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  companyFilter: [],
  locationFilter: [],
  customerFilter: [],
  projectFilter: [],
  financialYearFilter: [],
  //

  error: {},
  loading: true,
}

const VendorPaymentList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_FILTER_SUCCESS:
      return {
        ...state,
        companyFilter: action.payload,
        loading: true,
      }

    case GET_COMPANY_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
   
    case GET_PROJECT_FILTER_SUCCESS:
      return {
        ...state,
        projectFilter: action.payload,
        loading: true,
      }

    case GET_PROJECT_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
   
    default:
      return state
  }
}

export default VendorPaymentList
