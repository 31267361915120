import {
  GET_CITIES,
  GET_CITIES_FAIL,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_SUCCESS,
  GET_STATES,
  GET_STATES_FAIL,
  GET_STATES_SUCCESS,
} from "./actionTypes"

export const getCities = (stateId) => ({
  type: GET_CITIES,
  stateId,
})

export const getCitiesSuccess = companies => ({
  type: GET_CITIES_SUCCESS,
  payload: companies,
})

export const getCitiesFail = error => ({
  type: GET_CITIES_FAIL,
  payload: error,
})

export const getStates = (states) => ({
  type: GET_STATES,
  states
})

export const getStatesSuccess = companies => ({
  type: GET_STATES_SUCCESS,
  payload: companies,
})

export const getStatesFail = error => ({
  type: GET_STATES_FAIL,
  payload: error,
})

export const getCountries = () => ({
  type: GET_COUNTRIES,
})

export const getCountriesSuccess = companies => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: companies,
})

export const getCountriesFail = error => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
})
