//Pending Bills

export const GET_PENDING_BILL_LIST = "GET_PENDING_BILL_LIST"
export const GET_PENDING_BILL_LIST_SUCCESS = "GET_PENDING_BILL_LIST_SUCCESS"
export const GET_PENDING_BILL_LIST_FAIL = "GET_PENDING_BILL_LIST_FAIL"

//Bill History

export const GET_BILL_HISTORY_LIST = "GET_BILL_HISTORY_LIST"
export const GET_BILL_HISTORY_LIST_SUCCESS = "GET_BILL_HISTORY_LIST_SUCCESS"
export const GET_BILL_HISTORY_LIST_FAIL = "GET_BILL_HISTORY_LIST_FAIL"

//Get Project List

export const GET_PROJECT_LIST = "GET_PROJECT_LIST"
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS"
export const GET_PROJECT_LIST_FAIL = "GET_PROJECT_LIST_FAIL"

//Get Ticket List

export const GET_TICKET_LIST = "GET_TICKET_LIST"
export const GET_TICKET_LIST_SUCCESS = "GET_TICKET_LIST_SUCCESS"
export const GET_TICKET_LIST_FAIL = "GET_TICKET_LIST_FAIL"

//Get Expense List


export const GET_EXPENSE_LIST = "GET_EXPENSE_LIST"
export const GET_EXPENSE_LIST_SUCCESS = "GET_EXPENSE_LIST_SUCCESS"
export const GET_EXPENSE_LIST_FAIL = "GET_EXPENSE_LIST_FAIL"


//Get GST List
export const GET_GST_RATE_LIST = "GET_GST_RATE_LIST"
export const GET_GST_RATE_LIST_SUCCESS = "GET_GST_RATE_LIST_SUCCESS"
export const GET_GST_RATE_LIST_FAIL = "GET_GST_RATE_LIST_FAIL"

//Get GET_PROJECT_COMPANY_DETAILs List
export const GET_PROJECT_COMPANY_DETAIL = "GET_PROJECT_COMPANY_DETAIL"
export const GET_PROJECT_COMPANY_DETAIL_SUCCESS = "GET_PROJECT_COMPANY_DETAIL_SUCCESS"
export const GET_PROJECT_COMPANY_DETAIL_FAIL = "GET_PROJECT_COMPANY_DETAIL_FAIL"

//ADD NEW INVOICE
export const ADD_NEW_INVOICE = "ADD_NEW_INVOICE"
export const ADD_NEW_INVOICE_SUCCESS = "ADD_NEW_INVOICE_SUCCESS"
export const ADD_NEW_INVOICE_FAIL = "ADD_NEW_INVOICE_FAIL"

//Get GET_TICKET_DETAIL List
export const GET_TICKET_DETAIL = "GET_TICKET_DETAIL"
export const GET_TICKET_DETAIL_SUCCESS = "GET_TICKET_DETAIL_SUCCESS"
export const GET_TICKET_DETAIL_FAIL = "GET_TICKET_DETAIL_FAIL"

//DEBIT NOTE

//GET_DEBIT_NOTE_LIST List
export const GET_DEBIT_NOTE_LIST = "GET_DEBIT_NOTE_LIST"
export const GET_DEBIT_NOTE_LIST_SUCCESS = "GET_DEBIT_NOTE_LIST_SUCCESS"
export const GET_DEBIT_NOTE_LIST_FAIL = "GET_DEBIT_NOTE_LIST_FAIL"



