import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  FormFeedback,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { createSelector } from "reselect"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"
import {
  addNewInvoice,
  getExpenseList,
  getGstRateList,
  getProjectCompanyDetail,
  getProjectList,
  getTicketDetail,
  getTicketList,
} from "store/actions"
import { getAcceptedProposal } from "helpers/backend"

const AddDebitNote = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [clientId, setClientId] = useState()
  const [unitId, setUnitId] = useState()
  const [acceptedProposalList, setAcceptedProposalList] = useState([])
  const BillingState = state => state.billAndPayments
  const path = useLocation()
  const AllBillingProperties = createSelector(
    BillingState,

    billing => ({
      projectList: billing.projectList.data,
      expenseList: billing.expenseList.data,
      ticketList: billing.ticketList.data,
      gstRateList: billing.gstRateList.data,
      billingCompanyDetail: billing?.projectCompanyDetail?.data?.company,
      billingCompanylocation: billing?.projectCompanyDetail?.data?.location,
      ticketDetail: billing?.ticketDetail?.data,
    })
  )

  const {
    projectList,
    expenseList,
    ticketList,
    gstRateList,
    billingCompanyDetail,
    billingCompanylocation,
    ticketDetail,
  } = useSelector(AllBillingProperties)

  useEffect(() => {
    if (user) {
      dispatch(getProjectList(user.id))
    }
  }, [user])

  //FORMIK VALIDATION
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      expense_type_id: "",
      is_service_request: "",
      service_ticket_id: "",
      gst_percentage: "",
      file: "",
      project_id: "",
      invoice_date: new Date().toISOString().split("T")[0] || "",
      invoice_no: "",
      remarks: "",
      taxable_amount: "",
      proposal_id: "",
    },
    validationSchema: Yup.object().shape({
      gst_percentage: Yup.string().required("Please select Gst Applicable"),
      invoice_no: Yup.string().required("Please Enter Invoice Number"),
      taxable_amount: Yup.number()
        .positive()
        .typeError("Taxable Amount Must be Positive Integer")
        .required("Taxable Amount is Required"),
      project_id: Yup.number().required(" Please Select Project "),
      invoice_date: Yup.string().required("Invoice date is required"),
      is_service_request: Yup.string().required(
        "Is Service Request is required"
      ),
      expense_type_id: Yup.string().required("Expense type is required"),
      remarks: Yup.string().required("Remarks is required"),
    }),

    onSubmit: async values => {
      dispatch(addNewInvoice(props.router.navigate, values))
    },
  })

  useEffect(() => {
    const fetchAcceptedProposals = async () => {
      try {
        const response = await getAcceptedProposal();
        setAcceptedProposalList(response.proposal);
      } catch (error) {
        console.error('Failed to fetch accepted proposals', error);
      }
    };
  
    fetchAcceptedProposals();
  }, [dispatch]);
  
  useEffect(() => {
    if (validation.values.project_id) {
      dispatch(getProjectCompanyDetail(validation.values.project_id))
    }
  }, [validation.values.project_id])
  useEffect(() => {
    if (validation.values.is_service_request) {
      validation.setFieldValue("service_ticket_id", "")
    }
  }, [validation.values.is_service_request])

  useEffect(() => {
    if (validation.values.project_id && user) {
      const value = {
        project_id: validation.values.project_id,
        vendor_id: user.id,
      }
      dispatch(getTicketList(value))
    }
  }, [validation.values.project_id, user])

  useEffect(() => {
    dispatch(getGstRateList())
  }, [dispatch])

  useEffect(() => {
    dispatch(getExpenseList())
  }, [dispatch])
  useEffect(() => {
    if (validation.values.service_ticket_id) {
      dispatch(getTicketDetail(validation.values.service_ticket_id))
    }
  }, [validation.values.service_ticket_id])

  console.log(validation.values)
  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Form>
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Upload Invoice
              </div>
            </CardTitle>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.project_id &&
                              validation.errors.project_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="project_id"
                            value={validation.values.project_id || ""}
                          >
                            <option value="">Select Project</option>
                            {(projectList || []).map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_id &&
                            validation.errors.project_id && (
                              <div className="invalid-feedback">
                                {validation.errors.project_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.is_service_request &&
                              validation.errors.is_service_request
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="is_service_request"
                            value={validation.values.is_service_request || "No"}
                          >
                            <option value="">Is Service Request?</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                          {validation.touched.is_service_request &&
                            validation.errors.is_service_request && (
                              <div className="invalid-feedback">
                                {validation.errors.is_service_request}
                              </div>
                            )}
                        </div>
                      </Col>
                      {String(validation.values.is_service_request) === "1" && (
                        <Col lg={3}>
                          <div className="mb-3">
                            <select
                              className={`form-select ${
                                validation.touched.service_ticket_id &&
                                validation.errors.service_ticket_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={validation.handleChange}
                              name="service_ticket_id"
                              value={validation.values.service_ticket_id}
                            >
                              <option value="">Select Ticket</option>
                              {(ticketList || []).map(ticket => (
                                <option
                                  key={ticket.id}
                                  value={ticket.ticket_id}
                                >
                                  {ticket.ticket_no} ({ticket.ticket_date} )
                                </option>
                              ))}
                            </select>
                            {validation.touched.service_ticket_id &&
                              validation.errors.service_ticket_id && (
                                <div className="invalid-feedback">
                                  {validation.errors.service_ticket_id}
                                </div>
                              )}
                          </div>
                        </Col>
                      )}
                      <Col lg={3}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.proposal_id &&
                              validation.errors.proposal_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="proposal_id"
                            value={validation.values.proposal_id || "No"}
                          >
                            <option value="">Tagged Proposals</option>
                            {acceptedProposalList.map((proposal, index) => (
                              <option key={index} value={proposal.proposal_id}>
                                {proposal.proposal_no}
                              </option>
                            ))}
                          
                          </select>
                          {validation.touched.proposal_id &&
                            validation.errors.proposal_id && (
                              <div className="invalid-feedback">
                                {validation.errors.proposal_id}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3}>
                        {validation.values.project_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Company Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="name">Company Name</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="address_line_1">
                                        Address
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_1}{" "}
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_2}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pin_code">Pincode</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.pin_code}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="country_name">
                                        Country
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.country_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="state_name">State</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.state_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="city_name">City</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.city_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={3}>
                        {validation.values.project_id && (
                          <Card>
                            <CardBody
                              style={{
                                backgroundColor: "#EEEEEE",
                                borderRadius: "15px",
                              }}
                            >
                              <CardTitle className="mb-3">
                                Billing Company's Location Detail
                              </CardTitle>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Address</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.location_title}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Country</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.country_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">State</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.state_name}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">City</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.city_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">GST No.</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.gst_no}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                      <Col lg={3}>
                        {validation.values.service_ticket_id && (
                          <Card>
                            <CardBody
                              style={{
                                backgroundColor: "#EEEEEE",
                                borderRadius: "15px",
                              }}
                            >
                              <CardTitle className="mb-3">
                                Service Request Ticket Detail
                              </CardTitle>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Ticket no</Label>
                                    <p>
                                      {ticketDetail && ticketDetail.ticket_no}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Created at</Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.formatted_created_at}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Work Type</Label>
                                    <p>
                                      {ticketDetail && ticketDetail.work_type}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Priority</Label>
                                    <p>
                                      {ticketDetail && ticketDetail.priority_id}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Project Manager
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.project_manager_name}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Ticket Supervisor
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.ticket_supervisor_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Total Material
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.total_material_cost}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Total Manpower
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.total_manpower_cost}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Total Other Cost
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.total_other_cost}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">Invoice Details</CardTitle>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label>Taxable Amount</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>GST Rate(%)</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>GST Amount</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>Total Amount</Label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            className={`form-control ${
                              validation.touched.taxable_amount &&
                              validation.errors.taxable_amount
                                ? "is-invalid"
                                : ""
                            }`}
                            id="taxable_amount"
                            name="taxable_amount"
                            placeholder="Enter Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.taxable_amount || ""}
                          />
                          {validation.touched.taxable_amount &&
                            validation.errors.taxable_amount && (
                              <FormFeedback type="invalid">
                                {validation.errors.taxable_amount}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.gst_percentage &&
                              validation.errors.gst_percentage
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="gst_percentage"
                            value={validation.values.gst_percentage || ""}
                          >
                            <option value="">Select Gst Rate</option>
                            <option value="0">0</option>
                            {(gstRateList || []).map(gst => (
                              <option key={gst.id} value={gst.applicable_rate}>
                                {gst.applicable_rate}
                              </option>
                            ))}
                          </select>
                          {validation.touched.gst_percentage &&
                            validation.errors.gst_percentage && (
                              <div className="invalid-feedback">
                                {validation.errors.gst_percentage}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            id="amount"
                            name="amount"
                            disabled
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              (parseFloat(validation.values.taxable_amount) *
                                parseFloat(validation.values.gst_percentage)) /
                                100 || 0
                            }
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            id="total"
                            name="total"
                            disabled
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              parseFloat(validation.values.taxable_amount) +
                                (parseFloat(validation.values.taxable_amount) *
                                  parseFloat(
                                    validation.values.gst_percentage
                                  )) /
                                  100 || 0
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="invoice_no">Invoice Number*</Label>
                          <Input
                            type="text"
                            name="invoice_no"
                            className={`form-control ${
                              validation.touched.invoice_no &&
                              validation.errors.invoice_no
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            id="invoice_no"
                            placeholder="Enter Invoice Number"
                            value={validation.values.invoice_no || ""}
                          />
                          {validation.touched.invoice_no &&
                            validation.errors.invoice_no && (
                              <FormFeedback type="invalid">
                                {validation.errors.invoice_no}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="invoice_date">Invoice Date*</Label>
                          <Input
                            type="date"
                            id="invoice_date"
                            name="invoice_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.invoice_date}
                            className={`form-control ${
                              validation.touched.invoice_date &&
                              validation.errors.invoice_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.invoice_date &&
                            validation.errors.invoice_date && (
                              <FormFeedback type="invalid">
                                {validation.errors.invoice_date}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <Label htmlFor="document">Upload Invoice*</Label>
                        <input
                          type="file"
                          id="file"
                          name="file"
                          onChange={e => {
                            const selectedFile = e.target.files[0]
                            validation.setFieldValue(`file`, selectedFile)
                          }}
                          onBlur={validation.handleBlur}
                          className={`form-control ${
                            validation.touched.file && validation.errors.file
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {validation.touched.file && validation.errors.file && (
                          <div className="invalid-feedback">
                            {validation.errors.file}
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="expense_type_id">Expense Type*</Label>
                          <select
                            className={`form-select ${
                              validation.touched.expense_type_id &&
                              validation.errors.expense_type_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="expense_type_id"
                            value={validation.values.expense_type_id}
                          >
                            <option value="">Select Expense type</option>
                            {(expenseList || []).map(expense => (
                              <option key={expense.id} value={expense.id}>
                                {expense.expense_title}
                              </option>
                            ))}
                          </select>
                          {validation.touched.expense_type_id &&
                            validation.errors.expense_type_id && (
                              <div className="invalid-feedback">
                                {validation.errors.expense_type_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={8}>
                        <div className="mb-3">
                          <Label htmlFor="remarks">Remark*</Label>
                          <Input
                            type="textarea"
                            name="remarks"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            className={`form-control ${
                              validation.touched.remarks &&
                              validation.errors.remarks
                                ? "is-invalid"
                                : ""
                            }`}
                            id="remarks"
                            placeholder="Enter Remark"
                            value={validation.values.remarks || ""}
                          />
                          {validation.touched.remarks &&
                            validation.errors.remarks && (
                              <FormFeedback type="invalid">
                                {validation.errors.remarks}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button
                    type="button"
                    className="btn btn-primary w-md me-4"
                    style={{ width: "10%" }}
                    onClick={validation.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddDebitNote)

AddDebitNote.propTypes = {
  history: Proptypes.object,
}
