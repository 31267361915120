import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_LATEST_INVOICE,
  GET_LATEST_INVOICE_SUCCESS,
  GET_LATEST_INVOICE_FAIL,
  GET_PREVIOUS_INVOICES_LIST,
  GET_PREVIOUS_INVOICES_LIST_SUCCESS,
  GET_PREVIOUS_INVOICES_LIST_FAIL,
  GET_SERVICE_REQUEST,
  GET_SERVICE_REQUEST_SUCCESS,
  GET_SERVICE_REQUEST_FAIL,
  GET_LATEST_TICKET,
  GET_LATEST_TICKET_SUCCESS,
  GET_LATEST_TICKET_FAIL,
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
})

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
})

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
})

// export const getLatestInvoice = clientId => ({
//     type: GET_LATEST_INVOICE,
//     clientId,
//   })
  
//   export const getLatestInvoiceSuccess = latestInvoice => ({
//     type: GET_LATEST_INVOICE_SUCCESS,
//     payload: latestInvoice,
//   })
  
//   export const getLatestInvoiceFail = error => ({
//     type: GET_LATEST_INVOICE_FAIL,
//     payload: error,
//   })
export const getPreviousInvoicesList = clientId => ({
    type: GET_PREVIOUS_INVOICES_LIST,
    clientId,
  })
  
  export const getPreviousInvoicesListSuccess = previousInvoicesList => ({
    type: GET_PREVIOUS_INVOICES_LIST_SUCCESS,
    payload: previousInvoicesList,
  })
  
  export const getPreviousInvoicesListFail = error => ({
    type: GET_PREVIOUS_INVOICES_LIST_FAIL,
    payload: error,
  })
export const getServiceRequest = clientId => ({
    type: GET_SERVICE_REQUEST,
    clientId,
  })
  
  export const getServiceRequestSuccess = serviceRequest => ({
    type: GET_SERVICE_REQUEST_SUCCESS,
    payload: serviceRequest,
  })
  
  export const getServiceRequestFail = error => ({
    type: GET_SERVICE_REQUEST_FAIL,
    payload: error,
  })
  
  export const getLatestTicket = vendorId => ({
    type: GET_LATEST_TICKET,
    vendorId,
  })
  
  export const getLatestTicketSuccess = latestTicket => ({
    type: GET_LATEST_TICKET_SUCCESS,
    payload: latestTicket,
  })
  
  export const getLatestTicketFail = error => ({
    type: GET_LATEST_TICKET_FAIL,
    payload: error,
  })