import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getVendorDetail } from "store/actions"
import Spinners from "components/Common/Spinner"

const myProfile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [vendorId, setVendorId] = useState(authUser ? authUser.id : "")
  const vendorDetailState = state =>
    state.vendorProfilePreview.vendorDetail.data
  const loadingState = state => state.vendorProfilePreview.loading
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)
  const vendorDetail = useSelector(vendorDetailState) || []

  useEffect(() => {
    dispatch(getVendorDetail(vendorId))
  }, [dispatch])

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <div style={{ backgroundColor: "#E6E6E6" }}>
          <Container fluid={true}>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <CardTitle className="mb-3">
                  <div className="d-flex gap-2 mb-3 mt-5">
                    <i
                      className="mdi mdi-arrow-left font-size-20"
                      style={{ color: "grey" }}
                      onClick={() => {
                        navigate("/dashboard")
                      }}
                    ></i>
                    <h5 className="mt-1">My Profile</h5>
                  </div>
                </CardTitle>
                <Card className="overflow-hidden">
                  <div style={{ backgroundColor: "#F6F6F6" }}>
                    <Row className="mt-5">
                      <Col xs="5" className="align-self-end">
                        <img src="" alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody>
                    <Row>
                      <Col sm="4">
                        <div
                          className="avatar-lg profile-user-wid mb-3"
                          style={{ marginTop: "-50px" }}
                        >
                          <img
                            src={
                              vendorDetail && vendorDetail.vendor_details.image
                            }
                            alt="ALT"
                            className="img-thumbnail rounded-circle"
                            style={{ width: "120px", height: "100px" }}
                          />
                        </div>
                      </Col>
                      <Col sm="9">
                        <div className="pt-4">
                          <Row>
                            <Col xs="12">
                              <h5 className="font-size-15">
                                {vendorDetail &&
                                  vendorDetail.vendor_details.vendor_name}
                              </h5>
                              <p className="text-muted mb-0">
                                {vendorDetail &&
                                  vendorDetail.vendor_details.email_address}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <div className="d-inline-flex">
                              <Link to={"/change-password"}>
                                <Button
                                  className="mt-2"
                                  style={{
                                    backgroundColor: "#E6E6E6",
                                    color: "black",
                                    border: "none",
                                    fontSize: "10px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Change Password
                                </Button>
                              </Link>
                            </div>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-5">Profile Details</CardTitle>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Entity:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>
                          {vendorDetail &&
                            vendorDetail.vendor_details.entity}
                        </div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Vendor Name:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>
                          {vendorDetail &&
                            vendorDetail.vendor_details.vendor_name}
                        </div>
                      </div>
                    </div>

                    <hr></hr>
                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Vendor Code:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>
                          {" "}
                          {vendorDetail &&
                            vendorDetail.vendor_details.vendor_code}
                        </div>
                      </div>
                    </div>

                    <hr></hr>
                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Service Offering:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>
                          {" "}
                          {vendorDetail &&
                            vendorDetail.vendor_details.service_offering_name}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-5">Contact Details</CardTitle>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Email Address</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div> {vendorDetail && vendorDetail.vendor_details.email_address}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Phone No.</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.mobile_no}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Address</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.address}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Pincode</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.pincode}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">City</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.city_name}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">State</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.state_name}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Country</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.country_name}</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-5">Document Identities</CardTitle>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Pan No.</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.pan_no}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Aadhar Card:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.aadhar_no}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Tan No:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.tan_no}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">GST No:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.gst_no}</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-5">Bank Details</CardTitle>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Bank Name:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.bank_name}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">IFSC Code:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.ifsc_code}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Account No:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.account_no}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Account Holder Name:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{vendorDetail && vendorDetail.vendor_details.account_holder_name}</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default myProfile
