import {
  GET_ALL_NOTIFICATION_LIST_FAIL,
  GET_ALL_NOTIFICATION_LIST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  allNotifications: [],
  error: {},
  loading: true,
}

const Notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        allNotifications: action.payload,
        loading: false,
      }

    case GET_ALL_NOTIFICATION_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Notifications
