import React from "react"
import SimpleBar from "simplebar-react"
import ReactApexChart from "react-apexcharts"

import { Card, Col, CardBody, Table } from "reactstrap"

const category = () => {
  const getChartOptions = index => {
    var options = {
      chart: { sparkline: { enabled: !0 } },
      dataLabels: { enabled: !1 },
      colors: ["#556ee6"],
      plotOptions: {
        radialBar: {
          hollow: { margin: 0, size: "60%" },
          track: { margin: 0 },
          dataLabels: { show: !1 },
        },
      },
    }
    switch (index) {
      case 1:
        options["colors"][0] = "#556ee6"
        break
      case 2:
        options["colors"][0] = "#FFBF00"
        break
      case 3:
        options["colors"][0] = "#f46a6a"
        break
      case 4:
        options["colors"][0] = "#34c38f"
        break
      default:
        break
    }

    return options
  }

  const sellingData = [
    {
      name: "New Service Requests",
      desc: "Assigned to Vendor",
      value: 45,
    },
    {
      name: "Work in Progress Requests",
      desc: "Those Service Requests whose work is under progress",
      value: 60,
    },
    {
      name: "Waiting For Confirmation Requests",
      desc: "Those Service Requests Who are Waiting For Confirmation",
      value: 75,
    },
    {
      name: "Completed Requests",
      desc: "Completed Service Requests",
      value: 30,
    },
  ]
  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <div className="clearfix">
              <h4 className="card-title mb-4">Service Request Summary</h4>
            </div>
            <div className="table-responsive mt-4">
              <SimpleBar style={{ maxHeight: 300 }}>
                <Table
                  className="table align-middle mb-0"
                  style={{ overflowY: "auto" }}
                >
                  <tbody>
                    {(sellingData || []).map((data, key) => {
                      const options = getChartOptions(key + 1)
                      return (
                        <tr key={key}>
                          <td>
                            <h5 className="font-size-14 mb-1">{data.name}</h5>
                            <p className="text-muted mb-0">{data.desc}</p>
                          </td>

                          <td>
                            <div id={`radialchart-${key}`}>
                              <ReactApexChart
                                options={options}
                                series={[data.value]}
                                type="radialBar"
                                height={60}
                                width={60}
                                className="apex-charts"
                              />
                            </div>
                          </td>
                          <td>
                            <p className="text-muted mb-1">Count</p>
                            <h5 className="mb-0">{data.value} </h5>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default category
