import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./debitNoteListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  getBillHistoryList,
  getDebitNoteList,
} from "store/bills&payment/actions"
import formatNumberWithCommas from "components/Common/CommaSeparator"

const DebitNoteList = () => {
  const navigate = useNavigate()
  document.title = "Debit Note List "
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()
  const billHistoryListState = state => state.billAndPayments.debitNoteList.data
  const billHistory = useSelector(billHistoryListState) || []

  useEffect(() => {
    const value = {
      vendor_id: user.id,
      project_id: '',
    }
    dispatch(getDebitNoteList(value))
  }, [user])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.row.index + 1}</h5>
            </>
          )
        },
      },

      {
        Header: "Debit Note No.",
        accessor: "debit_note_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.debit_note_no}
              </h5>
            </>
          )
        },
      },
      {
        Header: " Debit Note Date",
        accessor: "debit_note_date_formated",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.debit_note_date_formated}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Against Invoice No.",
        accessor: "invoice_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.invoice_no}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Against Invoice Date",
        accessor: "invoice_date_formated",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.invoice_date_formated}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.vendor_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Name",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.project_name}
              </h5>
            </>
          )
        },
      },

      {
        Header: "Debit Amount",
        accessor: "all_total",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {formatNumberWithCommas(cellProps.row.original.all_total)}
              </h5>
            </>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Bills & Payment" breadcrumbItem="Debit Note" />

          <Row>
            {/* {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : ( */}
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    data={billHistory || []}
                    isGlobalFilter={true}
                    isShowingPageLength={true}
                    isAddUserList={true}
                    iscustomPageSizeOptions={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    tableClass="table align-middle table-nowrap table-hover"
                    theadClass="table-light"
                    paginationDiv="col-sm-12 col-md-7"
                    pagination="pagination pagination-rounded justify-content-end mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
            {/* )} */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DebitNoteList
