import { call, put, takeEvery } from "redux-saga/effects"
//  Redux States
import { GET_VENDOR_DETAIL } from "./actionTypes"
import { getVendorDetailSuccess, getVendorDetailFail } from "./actions"

import { getVendorDetail } from "helpers/backend"

function* fetchVendorDetail({ vendorId }) {
  try {
    const response = yield call(getVendorDetail, vendorId)
    yield put(getVendorDetailSuccess(response))
  } catch (error) {
    yield put(getVendorDetailFail(error))
  }
}

function* vendorProfilePreviewSaga() {
  yield takeEvery(GET_VENDOR_DETAIL, fetchVendorDetail)
}

export default vendorProfilePreviewSaga
