import React, { useDebugValue, useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import PropTypes from "prop-types"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"

import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  getCities,
  getCountries,
  getStates,
  getUserDetail,
  updateUser,
} from "store/actions"
import withRouter from "components/Common/withRouter"

const EditUser = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [vendorId, setSetVendorId] = useState(user?.id)
  const [currentVendorId, setCurrentVendorId] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userDetail = state => state.manageUser.userDetails.data
  const data = useSelector(userDetail) || []

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const [selectedFile, setSelectedFile] = useState()
  const [imageURL, setImageURL] = useState(null)
  const [imageTouched, setImageTouched] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      vendor_id: (vendorId && vendorId) || "",
      firstname: (data && data.firstname) || "",
      middlename: (data && data.lastname) || "",
      lastname: (data && data.lastname) || "",
      email_id: (data && data.email_id) || "",
      mobile_no: (data && data.mobile_no) || "",
      aadhar_card_no: (data && data.aadhar_card_no) || "",
      address: (data && data.address) || "",
      country: (data && data.country) || "",
      state: (data && data.state) || "",
      city: (data && data.city) || "",
      pin_code: (data && data.pin_code) || "",
      user_type: (data && String(data.user_type)) || "",
      user_data_access_type: (data && data.user_data_access_type) || "",
      login_username: (data && data.login_username) || "",
      is_active: (data && String(data.is_active)) || "0",
      image: "",
    },
    validationSchema: Yup.object().shape({
      vendor_id: Yup.number(),
      image: Yup.string(),
      firstname: Yup.string().required("Please Enter First Name"),
      middlename: Yup.string(),
      lastname: Yup.string().required("Please Enter Last Name"),
      email_id: Yup.string()
        .email("Invalid Email Format")
        .required("Please Enter Email Address"),
      mobile_no: Yup.string()
        .matches(
          /^[0-9]{10,12}$/,
          "Mobile Number must be between 10 to 12 digits"
        )
        .required("Please Enter Mobile Number "),
      aadhar_card_no: Yup.string()
        .matches(/^\d{12}$/, "Aadhar Number must be a 12-digit number")
        .required("Please Enter Aadhar Number"),
      address: Yup.string().required("Please Enter Address"),
      city: Yup.string().required("Please Enter City"),
      state: Yup.string().required("Please Enter State"),
      country: Yup.string().required("Please Enter Country"),
      pin_code: Yup.string()
        .matches(/^\d{6}$/, "Pincode must be a 6-digit number")
        .required("Please Enter Pincode"),
      user_type: Yup.string().required("Please Select Area of Work"),
      user_data_access_type: Yup.string().required(
        "Please Select Data Access Type"
      ),
      login_username: Yup.string().required("Please Enter Login Username"),
      // is_active: Yup.string(),
    }),

    onSubmit: async values => {
      dispatch(updateUser(currentVendorId, values, props.router.navigate))
    },
  })
  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState(validation.values.state)

  const handleFileChange = event => {
    const file = event.target.files[0]
    setSelectedFile(file)
    if (typeof file === "object") {
      setImageURL(URL.createObjectURL(file))
    } else {
      setImageURL(file)
    }
  }

  useEffect(() => {
    setImageURL(data?.image)
    setSelectedFile(data?.image)
  }, [data?.image])

  useEffect(() => {
    if (selectedFile && typeof selectedFile === "object") {
      if (validation.touched.image && !imageTouched) {
        validation.setFieldValue("image", selectedFile)
        setImageTouched(true)
      }
    }
  }, [
    selectedFile,
    validation.touched.image,
    imageTouched,
    validation.setFieldValue,
  ])

  useEffect(() => {
    const fetchData = () => {
      dispatch(getCountries())
      if (validation.values.country) {
        dispatch(getStates(validation.values.country))
        if (validation.values.state !== "") {
          dispatch(getCities(validation.values.state))
        }
      }
      setCountryName(validation.values.country)
      setStateId(validation.values.state)
    }
    fetchData()
  }, [dispatch, validation.values.country, validation.values.state])

  const vendorData = () => {
    const url = new URL(window.location.href)
    const currentVendorId = url.pathname.split("/edit-user/")[1]
    setCurrentVendorId(currentVendorId)
    dispatch(getUserDetail(currentVendorId))
  }
  useEffect(() => {
    vendorData()
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <React.Fragment>
          <i
            className="mdi mdi-arrow-left font-size-20"
            style={{ color: "grey", marginRight: "20px" }}
            onClick={() => navigate(-1)}
          ></i>
          <span style={{ color: "black", fontWeight: "500", fontSize: "18px" }}>
            Edit Employee
          </span>
        </React.Fragment>
        <div className="mt-3"></div>
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <label
                              className="rounded-2"
                              style={{
                                width: "150px",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                borderRadius: "10px",
                                border: "1px solid rgba(0, 0, 0, 0.10)",
                                flexDirection: "column",
                              }}
                            >
                              {imageURL ? (
                                <img
                                  className="object-fit-fill rounded-2"
                                  src={imageURL}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    maxHeight: "150px",
                                    minHeight: "150px",
                                  }}
                                />
                              ) : (
                                <i
                                  className="mdi mdi-image-outline"
                                  style={{
                                    fontSize: "60px",
                                    padding: "30px",
                                  }}
                                ></i>
                              )}
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                            </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                First Name*
                              </Label>
                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                placeholder="Enter First Name"
                                name="firstname"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstname || ""}
                                className={`form-control ${
                                  validation.touched.firstname &&
                                  validation.errors.firstname
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.firstname &&
                              validation.errors.firstname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstname}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="middlename">Middle Name</Label>
                              <Input
                                type="text"
                                id="middlename"
                                placeholder="Enter Middle Name"
                                name="middlename"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.middlename || ""}
                                className={`form-control ${
                                  validation.touched.middlename &&
                                  validation.errors.middlename
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.middlename &&
                              validation.errors.middlename ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.middlename}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="lastname">Last Name*</Label>
                              <Input
                                type="text"
                                id="lastname"
                                placeholder="Enter Last Name"
                                name="lastname"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastname || ""}
                                className={`form-control ${
                                  validation.touched.lastname &&
                                  validation.errors.lastname
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.lastname &&
                              validation.errors.lastname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastname}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="email_id">Email Address*</Label>
                              <Input
                                type="text"
                                name="email_id"
                                className="form-control"
                                id="email_id"
                                placeholder="Enter Vendor's Email Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email_id || ""}
                                invalid={
                                  validation.touched.email_id &&
                                  validation.errors.email_id
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email_id &&
                              validation.errors.email_id ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email_id}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="mobile">Mobile No.*</Label>
                              <Input
                                type="text"
                                name="mobile_no"
                                className="form-control"
                                id="mobile_no"
                                placeholder="Enter Vendor's Mobile Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobile_no || ""}
                                invalid={
                                  validation.touched.mobile_no &&
                                  validation.errors.mobile_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.mobile_no &&
                              validation.errors.mobile_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mobile_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="address">Address*</Label>
                              <Input
                                type="text"
                                name="address"
                                className="form-control"
                                id="address"
                                placeholder="Enter Vendor's Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                              validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="pin_code">Pincode*</Label>
                              <Input
                                type="text"
                                name="pin_code"
                                className="form-control"
                                id="pin_code"
                                placeholder="Enter Pincode"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pin_code || ""}
                                invalid={
                                  validation.touched.pin_code &&
                                  validation.errors.pin_code
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.pin_code &&
                              validation.errors.pin_code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pin_code}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="country">Country*</Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.country}
                                name="country"
                                className={`form-select ${
                                  validation.touched.country &&
                                  validation.errors.country
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.country && (
                                  <option>Select Your Country</option>
                                )}

                                {(countries || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.country_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="formrow-state-Input">
                                State*
                              </Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="state"
                                value={validation.values.state}
                                className={`form-select ${
                                  validation.touched.state &&
                                  validation.errors.state
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.state && (
                                  <option>Enter Your States</option>
                                )}

                                {(states || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.state_name}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.state &&
                              validation.errors.state ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.state}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-city-Input">City*</Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="city"
                                value={validation.values.city}
                                className={`form-select ${
                                  validation.touched.city &&
                                  validation.errors.city
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.city && (
                                  <option>Enter Your City</option>
                                )}

                                {(cities || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.city_name}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.city &&
                              validation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="aadhar_card_no">Aadhar no*</Label>
                              <Input
                                type="text"
                                name="aadhar_card_no"
                                className="form-control"
                                id="aadhar_card_no"
                                placeholder="Enter Aadhar Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.aadhar_card_no || ""}
                                invalid={
                                  validation.touched.aadhar_card_no &&
                                  validation.errors.aadhar_card_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.aadhar_card_no &&
                              validation.errors.aadhar_card_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.aadhar_card_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="user_type">Area of Work*</Label>
                              <Col lg="6">
                                <div className="form-check">
                                  <Input
                                    type="radio"
                                    id="user_type_1"
                                    name="user_type"
                                    value={1}
                                    onClick={validation.handleChange}
                                    checked={String(validation.values.user_type) === '1'}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.user_type &&
                                      validation.errors.user_type
                                        ? true
                                        : false
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="user_type_1"
                                    style={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <h6>Administrator</h6>
                                  </Label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    type="radio"
                                    id="user_type_2"
                                    name="user_type"
                                    value={2}
                                    onClick={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    checked={String(validation.values.user_type) === '2'}

                                    invalid={
                                      validation.touched.user_type &&
                                      validation.errors.user_type
                                        ? true
                                        : false
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="user_type_2"
                                    style={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <h6>Billing Process</h6>
                                  </Label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    type="radio"
                                    id="user_type_3"
                                    name="user_type"
                                    value={3}
                                    onClick={validation.handleChange}
                                    checked={String(validation.values.user_type) === '3'}

                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.user_type &&
                                      validation.errors.user_type
                                        ? true
                                        : false
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="user_type_3"
                                    style={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <h6>Service Request Handling</h6>
                                  </Label>
                                </div>
                                {validation.touched.user_type &&
                                validation.errors.user_type ? (
                                  <FormFeedback
                                    type="invalid"
                                    style={{ display: "block" }}
                                  >
                                    {validation.errors.user_type}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="user_data_access_type">
                                Data Access Type*
                              </Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_data_access_type}
                                name="user_data_access_type"
                                className={`form-select ${
                                  validation.touched.user_data_access_type &&
                                  validation.errors.user_data_access_type
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.user_data_access_type && (
                                  <option>Select Data Access Type</option>
                                )}

                                <option value="1">Own Data</option>
                                <option value="2">All Data</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Sign in details</CardTitle>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="login_username">
                                Login Username*
                              </Label>
                              <Input
                                type="text"
                                name="login_username"
                                className="form-control"
                                id="login_username"
                                placeholder="Vendors Username"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.login_username || ""}
                                invalid={
                                  validation.touched.login_username &&
                                  validation.errors.login_username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.login_username &&
                              validation.errors.login_username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.login_username}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <Label htmlFor="is_active">Is Active*</Label>
                            <Col lg="6">
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="isActiveYes"
                                  name="is_active"
                                  value="1"
                                  onClick={() =>
                                    validation.setFieldValue("is_active", "1")
                                  }
                                  checked={validation.values.is_active === "1"}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="isActiveYes"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>Yes</h6>
                                </Label>
                              </div>
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="isActiveNo"
                                  name="is_active"
                                  value="0"
                                  onClick={() =>
                                    validation.setFieldValue("is_active", "0")
                                  }
                                  checked={validation.values.is_active === "0"}
                                />

                                <Label
                                  className="form-check-label"
                                  htmlFor="isActiveNo"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>No</h6>
                                </Label>
                              </div>
                              {validation.touched.is_active &&
                              validation.errors.is_active ? (
                                <div className="text-danger">
                                  {validation.errors.is_active}
                                </div>
                              ) : null}
                            </Col>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Form>
                  <Row className="mb-3">
                    <Col className="text-end">
                      <button
                        type="submit"
                        onClick={validation.handleSubmit}
                        className="btn btn-primary w-lg"
                      >
                        Edit
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditUser)

EditUser.propTypes = {
  history: PropTypes.object,
}
