import {
  GET_PENDING_BILL_LIST,
  GET_PENDING_BILL_LIST_SUCCESS,
  GET_PENDING_BILL_LIST_FAIL,
  GET_BILL_HISTORY_LIST,
  GET_BILL_HISTORY_LIST_SUCCESS,
  GET_BILL_HISTORY_LIST_FAIL,
  GET_PROJECT_LIST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_FAIL,
  GET_GST_RATE_LIST,
  GET_GST_RATE_LIST_SUCCESS,
  GET_GST_RATE_LIST_FAIL,
  GET_TICKET_LIST_FAIL,
  GET_TICKET_LIST_SUCCESS,
  GET_TICKET_LIST,
  GET_PROJECT_COMPANY_DETAIL,
  GET_PROJECT_COMPANY_DETAIL_SUCCESS,
  GET_PROJECT_COMPANY_DETAIL_FAIL,
  GET_EXPENSE_LIST,
  GET_EXPENSE_LIST_SUCCESS,
  GET_EXPENSE_LIST_FAIL,
  ADD_NEW_INVOICE,
  ADD_NEW_INVOICE_SUCCESS,
  ADD_NEW_INVOICE_FAIL,
  GET_TICKET_DETAIL,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_DETAIL_FAIL,
  GET_DEBIT_NOTE_LIST,
  GET_DEBIT_NOTE_LIST_SUCCESS,
  GET_DEBIT_NOTE_LIST_FAIL,
} from "./actionTypes"


export const getPendingBillList = value => ({
  type: GET_PENDING_BILL_LIST,
  value,
})

export const getPendingBillListSuccess = pendingBillList => ({
  type: GET_PENDING_BILL_LIST_SUCCESS,
  payload: pendingBillList,
})

export const getPendingBillListFail = error => ({
  type: GET_PENDING_BILL_LIST_FAIL,
  payload: error,
})

export const getBillHistoryList = vendorId => ({
  type: GET_BILL_HISTORY_LIST,
  vendorId,
})

export const getBillHistoryListSuccess = billHistoryList => ({
  type: GET_BILL_HISTORY_LIST_SUCCESS,
  payload: billHistoryList,
})

export const getBillHistoryListFail = error => ({
  type: GET_BILL_HISTORY_LIST_FAIL,
  payload: error,
})

// --------------------------------

export const getProjectList = vendorId => ({
  type: GET_PROJECT_LIST,
  vendorId,
})

export const getProjectListSuccess = projectList => ({
  type: GET_PROJECT_LIST_SUCCESS,
  payload: projectList,
})

export const getProjectListFail = error => ({
  type: GET_PROJECT_LIST_FAIL,
  payload: error,
})
//ticket list
export const getTicketList = value => ({
  type: GET_TICKET_LIST,
  value,
})

export const getTicketListSuccess = ticketList => ({
  type: GET_TICKET_LIST_SUCCESS,
  payload: ticketList,
})

export const getTicketListFail = error => ({
  type: GET_TICKET_LIST_FAIL,
  payload: error,
})
//expense list

export const getExpenseList = vendorId => ({
  type: GET_EXPENSE_LIST,
  vendorId,
})

export const getExpenseListSuccess = expenseList => ({
  type: GET_EXPENSE_LIST_SUCCESS,
  payload: expenseList,
})

export const getExpenseListFail = error => ({
  type: GET_EXPENSE_LIST_FAIL,
  payload: error,
})

//gst rate list
export const getGstRateList = vendorId => ({
  type: GET_GST_RATE_LIST,
  vendorId,
})

export const getGstRateListSuccess = gstRateList => ({
  type: GET_GST_RATE_LIST_SUCCESS,
  payload: gstRateList,
})

export const getGstRateListFail = error => ({
  type: GET_GST_RATE_LIST_FAIL,
  payload: error,
})

//projectCompanyDetail list

export const getProjectCompanyDetail = projectId => ({
  type: GET_PROJECT_COMPANY_DETAIL,
  projectId,
})

export const getProjectCompanyDetailSuccess = projectCompanyDetail => ({
  type: GET_PROJECT_COMPANY_DETAIL_SUCCESS,
  payload: projectCompanyDetail,
})

export const getProjectCompanyDetailFail = error => ({
  type: GET_PROJECT_COMPANY_DETAIL_FAIL,
  payload: error,
})

export const addNewInvoice = (history, invoice) => ({
  type: ADD_NEW_INVOICE,
  payload: { history, invoice },
})

export const addNewInvoiceSuccess = invoice => ({
  type: ADD_NEW_INVOICE_SUCCESS,
  payload: invoice,
})

export const addNewInvoiceFail = error => ({
  type: ADD_NEW_INVOICE_FAIL,
  payload: error,
})

export const getTicketDetail = ticketId => ({
  type: GET_TICKET_DETAIL,
  ticketId,
})

export const getTicketDetailSuccess = ticketDetail => ({
  type: GET_TICKET_DETAIL_SUCCESS,
  payload: ticketDetail,
})

export const getTicketDetailFail = error => ({
  type: GET_TICKET_DETAIL_FAIL,
  payload: error,
})


//DEBIT NOTE
export const getDebitNoteList = value => ({
  type: GET_DEBIT_NOTE_LIST,
  value,
})

export const getDebitNoteListSuccess = debitList => ({
  type: GET_DEBIT_NOTE_LIST_SUCCESS,
  payload: debitList,
})

export const getDebitNoteListFail = error => ({
  type: GET_DEBIT_NOTE_LIST_FAIL,
  payload: error,
})