import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  Row,
  Table,
} from "reactstrap"
import { Link, NavLink } from "react-router-dom"
import SimpleBar from "simplebar-react"
// import logo from "../../assets/images/VipVendors/NoBgLogo.png"
import logo from "../../assets/images/VipVendors/V1MallCropped.svg"

import altLogo from "../../assets/images/VipVendors/emptyuser.png"
import { useDispatch } from "react-redux"
import { getVendorDetail } from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const vendorProfilePreview = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [vendorId, setVendorId] = useState(authUser ? authUser.id : "")

  const dispatch = useDispatch()
  const vendorDetailState = state =>
    state.vendorProfilePreview.vendorDetail.data
  const loadingState = state => state.vendorProfilePreview.loading
  const vendorProfilePreviewProperties = createSelector(
    vendorDetailState,
    loadingState,
    (vendorDetail, loading) => ({
      vendorDetail: vendorDetail,
      loading: loading,
    })
  )

  const { vendorDetail, loading } =
    useSelector(vendorProfilePreviewProperties) || []
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    dispatch(getVendorDetail(vendorId))
  }, [dispatch])

  return (
    <React.Fragment>
      <header>
        <div className="navbar-header">
          <div className="d-flex">
            <span className="logo-sm ms-5">
              <img src={logo} alt="" height="35" />
            </span>
          </div>
          <div className="d-flex">
            <Link to={"/change-password"}>
              <Button
                className="me-3"
                style={{
                  backgroundColor: "#E6E6E6",
                  color: "black",
                  border: "none",
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                Change Password
              </Button>
            </Link>
            <Link to={"/dashboard"}>
              <Button
                className="me-5"
                style={{
                  backgroundColor: "#3882E8",
                  color: "white",
                  border: "none",
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                Proceed to Dashboard
              </Button>
            </Link>
          </div>
        </div>
      </header>
      <div className="page-content" style={{ backgroundColor: "#E6E6E6" }}>
        {" "}
        <Container fluid={true}>
          <div className="row justify-content-center">
            <div className="col-md-5">
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#F6F6F6" }}>
                  <Row className="mt-5">
                    <Col>
                      <div className="p-3">
                        <h5>
                          <strong>Welcome</strong>
                        </h5>
                        <p>to our Vendor portal</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src="" alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row className="mt-">
                    <Col sm="4">
                      <div className="avatar-lg profile-user-wid mb-4">
                        <img
                          src={
                            vendorDetail && vendorDetail.vendor_details.image
                          }
                          alt="ALT"
                          className="img-thumbnail rounded-circle"
                          style={{ height: "90px" }}
                        />
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="pt-4">
                        <Row>
                          <Col xs="6">
                            <h5 className="font-size-15">
                              {vendorDetail &&
                                vendorDetail.vendor_details.vendor_name}
                            </h5>
                            <p className="text-muted mb-0">
                              {vendorDetail &&
                                vendorDetail.vendor_details.email_address}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-5">Documents</CardTitle>

                  <Row className="mt-5">
                    <div className="table-responsive mt-4">
                      <table className="table align-middle table-nowrap">
                        <tbody>
                          <tr>
                            <td style={{ width: "20%", fontWeight: "600" }}>
                              <p className="mb-0 ">Document Name</p>
                            </td>
                            <td style={{ width: "20%", fontWeight: "600" }}>
                              <p className="mb-0 ">Action</p>
                            </td>
                          </tr>
                          {vendorDetail &&
                            vendorDetail.vendor_documents.map((docs, index) => (
                              <tr key={index}>
                                <td style={{ width: "20%" }}>
                                  <p className="mb-0">
                                    <button
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                    ></button>
                                    {docs.document_title}
                                  </p>
                                </td>
                                <td style={{ width: "20%" }}>
                                  <a
                                    href={docs.download_file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-success"
                                    download
                                  >
                                    <i
                                      style={{ color: "#3366cc" }}
                                      className="dripicons-download me-2"
                                    />
                                  </a>
                                  <a
                                    href={docs.document_path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-success"
                                  >
                                    <i
                                      className="mdi mdi-chevron-right font-size-18"
                                      style={{ color: "#3366cc" }}
                                    />
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="col-md-5">
              <SimpleBar style={{ maxHeight: "700px" }}>
                <div className="table-responsive">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">Profile Details</CardTitle>
                      <ul type="none">
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Entity:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.entity}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Vendor Name:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.vendor_name}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Vendor Code:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.vendor_code}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">
                                  Service Offering:
                                </h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details
                                      .service_offering_name}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                      </ul>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">Contact Details</CardTitle>
                      <ul type="none">
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Email Address:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details
                                      .email_address}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Phone No:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.mobile_no}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Address:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.address}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>

                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Pincode:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.pincode}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">City:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.city_name}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">State:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.state_name}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Country:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details
                                      .country_name}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                      </ul>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">
                        Document Identities
                      </CardTitle>
                      <ul type="none">
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Pan No:</h5>
                              </div>
                              <div className="flex-grow-1">
                                {vendorDetail &&
                                  vendorDetail.vendor_details.pan_no}{" "}
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Aadhar No:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.aadhar_no}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Tan No:</h5>
                              </div>
                              <div className="flex-grow-1">
                                {vendorDetail &&
                                  vendorDetail.vendor_details.tan_no}{" "}
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">GST No:</h5>
                              </div>
                              <div className="flex-grow-1">
                                {vendorDetail &&
                                  vendorDetail.vendor_details.gst_no}{" "}
                              </div>
                            </div>
                          </li>
                        }
                      </ul>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">Bank Details</CardTitle>
                      <ul type="none">
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Bank Name:</h5>
                              </div>
                              <div className="flex-grow-1">
                                {vendorDetail &&
                                  vendorDetail.vendor_details.bank_name}{" "}
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">IFSC Code:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {" "}
                                  {vendorDetail &&
                                    vendorDetail.vendor_details.ifsc_code}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Account No:</h5>
                              </div>
                              <div className="flex-grow-1">
                                {vendorDetail &&
                                  vendorDetail.vendor_details.account_no}{" "}
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">
                                  Account Holder Name:
                                </h5>
                              </div>
                              <div className="flex-grow-1">
                                {vendorDetail &&
                                  vendorDetail.vendor_details
                                    .account_holder_name}
                              </div>
                            </div>
                          </li>
                        }
                      </ul>
                    </CardBody>
                  </Card>
                </div>
              </SimpleBar>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default vendorProfilePreview
