const getStatusColorTicket = status => {
  switch (status) {
    case 6:
      return "red"
    case 1:
      return "blue"
    case 2:
      return "purple"
    case 3:
      return "orange"
    case 4:
      return "purple"
    case 5:
      return "brown"
    case 7:
      return "green"
    case 8:
      return "magenta"
    case 9:
      return "red"
    case 10:
      return "pink"
    case 11:
      return "yellow" // Assuming you want yellow for status 11
    case 12:
      return "#17c7c0" // Adding cyan for status 12
    default:
      return "black"
  }
}

export default getStatusColorTicket
