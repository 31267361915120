import {
  GET_NEW_SERVICE_REQUESTS,
  GET_NEW_SERVICE_REQUESTS_SUCCESS,
  GET_NEW_SERVICE_REQUESTS_FAIL,
  GET_WIP_SERVICE_REQUESTS,
  GET_WIP_SERVICE_REQUESTS_SUCCESS,
  GET_WIP_SERVICE_REQUESTS_FAIL,
  GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS,
  GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_SUCCESS,
  GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_FAIL,
  GET_COMPLETED_SERVICE_REQUESTS,
  GET_COMPLETED_SERVICE_REQUESTS_SUCCESS,
  GET_COMPLETED_SERVICE_REQUESTS_FAIL,
  GET_SERVICE_REQUEST_DETAIL,
  GET_SERVICE_REQUEST_DETAIL_SUCCESS,
  GET_SERVICE_REQUEST_DETAIL_FAIL,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST_FAIL,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL,
} from "./actionTypes"

export const getNewServiceRequests = id => ({
  type: GET_NEW_SERVICE_REQUESTS,
  id,
})

export const getNewServiceRequestsSuccess = serviceRequests => ({
  type: GET_NEW_SERVICE_REQUESTS_SUCCESS,
  payload: serviceRequests,
})

export const getNewServiceRequestsFail = error => ({
  type: GET_NEW_SERVICE_REQUESTS_FAIL,
  payload: error,
})

export const getWipServiceRequests = id => ({
  type: GET_WIP_SERVICE_REQUESTS,
  id,
})

export const getWipServiceRequestsSuccess = serviceRequests => ({
  type: GET_WIP_SERVICE_REQUESTS_SUCCESS,
  payload: serviceRequests,
})

export const getWipServiceRequestsFail = error => ({
  type: GET_WIP_SERVICE_REQUESTS_FAIL,
  payload: error,
})

export const getWaitingForApprovalServiceRequests = id => ({
  type: GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS,
  id,
})

export const getWaitingForApprovalServiceRequestsSuccess = serviceRequests => ({
  type: GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_SUCCESS,
  payload: serviceRequests,
})

export const getWaitingForApprovalServiceRequestsFail = error => ({
  type: GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_FAIL,
  payload: error,
})

export const getCompletedServiceRequests = id => ({
  type: GET_COMPLETED_SERVICE_REQUESTS,
  id,
})

export const getCompletedServiceRequestsSuccess = serviceRequests => ({
  type: GET_COMPLETED_SERVICE_REQUESTS_SUCCESS,
  payload: serviceRequests,
})

export const getCompletedServiceRequestsFail = error => ({
  type: GET_COMPLETED_SERVICE_REQUESTS_FAIL,
  payload: error,
})

export const updateServiceRequest = (id, serviceRequest) => ({
  type: UPDATE_SERVICE_REQUEST,
  payload: { id, serviceRequest },
})

export const updateServiceRequestSuccess = serviceRequest => ({
  type: UPDATE_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
})

export const updateServiceRequestFail = error => ({
  type: UPDATE_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const getServiceRequestDetail = serviceRequestId => ({
  type: GET_SERVICE_REQUEST_DETAIL,
  serviceRequestId,
})

export const getServiceRequestDetailSuccess = serviceRequestDetails => ({
  type: GET_SERVICE_REQUEST_DETAIL_SUCCESS,
  payload: serviceRequestDetails,
})

export const getServiceRequestDetailFail = error => ({
  type: GET_SERVICE_REQUEST_DETAIL_FAIL,
  payload: error,
})


export const getMaterialMasterForServiceRequest = () => ({
  type: GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST,
})

export const getMaterialMasterForServiceRequestSuccess = materials => ({
  type: GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  payload: materials,
})

export const getMaterialMasterForServiceRequestFail = error => ({
  type: GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const getUnitMasterForServiceRequest = () => ({
  type: GET_UNIT_MASTER_FOR_SERVICE_REQUEST,
})

export const getUnitMasterForServiceRequestSuccess = units => ({
  type: GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  payload: units,
})

export const getUnitMasterForServiceRequestFail = error => ({
  type: GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL,
  payload: error,
})
