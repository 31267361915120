import React, { useEffect, useState } from "react"
import { Col, Button, Row, Input, Label } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"

import {
  getBillHistoryList,
  getCompanyFilter,
  getDebitNoteList,
  getPendingBillList,
  getProjectFilter,
} from "store/actions"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  const projectstate = state => state.VendorPaymentList.projectFilter.data
  const projectList = useSelector(projectstate) || []
  const companystate = state => state.VendorPaymentList.companyFilter.data
  const companyList = useSelector(companystate) || []

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [project, setProject] = useState("")
  const [company, setCompany] = useState("")
  const [approval, setApproval] = useState("")
  const [status, setStatus] = useState("")

  const dispatch = useDispatch()

  const handleSelectProject = ele => {
    const value = ele.value
    setProject(value)
  }
  const handleSelectCompany = ele => {
    const value = ele.value
    setCompany(value)
  }
  const handleSelectApprovalStatus = ele => {
    const value = ele.value
    setApproval(value)
  }
  const handleSelectStatus = ele => {
    const value = ele.value
    setStatus(value)
  }

  const handleSearch = () => {
    const value = {
      vendor_id: user.id,
      project_id: project,
    }
    console.log(value, "value")
    dispatch(getDebitNoteList(value))
  }

  useEffect(() => {
    dispatch(getProjectFilter())
  }, [])
  useEffect(() => {
    if (project) {
      dispatch(getCompanyFilter(project))
    }
  }, [project])

  return (
    <React.Fragment>
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleSelectProject(e.target)}
        >
          <option value="">Select Project</option>
          {(projectList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.project_name}
            </option>
          ))}
        </select>
      </Col>
      {/* <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Company"
          onChange={e => handleSelectCompany(e.target)}
        >
          <option value="">Select Company</option>
          {(companyList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </Col> */}
     
    

      <Col xxl={1} lg={1} className="mb -3">
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default JobListGlobalFilter
