import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"
//  Redux States
import {
  GET_USERS_LIST,
  GET_USER_DETAIL,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  RESET_USER_PASSWORD,
} from "./actionTypes"
import {
  getUsersListSuccess,
  getUsersListFail,
  getUserDetailSuccess,
  getUserDetailFail,
  addNewUserSuccess,
  addNewUserFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  resetUserPasswordSuccess,
  resetUserPasswordFail,
} from "./actions"

import {
  getUsersList,
  getUserDetail,
  addNewUser,
  updateUser,
  deleteUser,
  resetUserPassword,
} from "helpers/backend"

function* fetchUsersList() {
  try {
    const response = yield call(getUsersList)
    yield put(getUsersListSuccess(response))
  } catch (error) {
    yield put(getUsersListFail(error))
  }
}

function* fetchUserDetail({ userId }) {
  try {
    const response = yield call(getUserDetail, userId)
    yield put(getUserDetailSuccess(response))
  } catch (error) {
    yield put(getUserDetailFail(error))
  }
}

function* onAddNewUser({ payload: { user, history } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addNewUser, user)
    yield put(addNewUserSuccess(response))
    if (response.status === true) {
      loadingSwal.close()
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your User has been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/manage-users/all-users")
        }
      })
    } else {
      loadingSwal.close()
      Swal.fire({
        title: "failure",
        icon: "error",
        text: response.message,
      })
    }
  } catch (error) {
    yield put(addNewUserFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.response.data.message,
    })
  }
}

function* onUpdateUser({ payload: { id, user, history } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(updateUser, id, user)
    yield put(updateUserSuccess(response))
    if (response.status === true) {
      loadingSwal.close()
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your User has been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/manage-users/all-users")
        }
      })
    } else {
      loadingSwal.close()
      Swal.fire({
        title: "failure",
        icon: "error",
        text: response.message,
      })
    }
  } catch (error) {
    yield put(updateUserFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message,
    })
  }
}

function* onDeleteUser({ user }) {
  try {
    const response = yield call(deleteUser, user)
    yield put(deleteUserSuccess(response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onResetUserPassword({ payload: { id, user, history } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(resetUserPassword, id, user, history)
    yield put(resetUserPasswordSuccess(response))
    if (response.status === true) {
      loadingSwal.close()
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "You have Successfully Reset User's Password.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/manage-users/all-users")
        }
      })
    } else {
      loadingSwal.close()
      Swal.fire({
        title: "failure",
        icon: "error",
        text: response.message,
      })
    }
  } catch (error) {
    yield put(resetUserPasswordFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message,
    })
  }
}

function* manageUserSaga() {
  yield takeEvery(GET_USERS_LIST, fetchUsersList)
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(RESET_USER_PASSWORD, onResetUserPassword)
}

export default manageUserSaga
