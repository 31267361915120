import { call, put, takeEvery } from "redux-saga/effects"

import {
  getAllNotificationListSuccess,
  getAllNotificationListFail,
} from "./actions"
import { GET_ALL_NOTIFICATION_LIST } from "./actionTypes"
import { getAllNotificationList } from "helpers/backend"

function* fetchAllCompanies({data}) {
  try {
    const response = yield call(getAllNotificationList,data)
    yield put(getAllNotificationListSuccess(response))
  } catch (error) {
    yield put(getAllNotificationListFail(error))
  }
}

function* notificationSaga() {
  yield takeEvery(GET_ALL_NOTIFICATION_LIST, fetchAllCompanies)
}

export default notificationSaga
