import {
  GET_SERVICE_TICKETS_LIST_SUCCESS,
  GET_SERVICE_TICKETS_LIST_FAIL,
  GET_SERVICE_TICKETS_DETAIL_SUCCESS,
  GET_SERVICE_TICKETS_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  serviceTicket: [],
  serviceTicketDetail: {},

  error: {},
  loading: true,
}

const ServiceTicket = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_TICKETS_LIST_SUCCESS:
      return {
        ...state,
        serviceTicket: action.payload,
        loading: true,
      }

    case GET_SERVICE_TICKETS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_TICKETS_DETAIL_SUCCESS:
      return {
        ...state,
        serviceTicketDetail: action.payload,
      }

    case GET_SERVICE_TICKETS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default ServiceTicket
