import React from "react"
import getChartColorsArray from "components/Common/ChartsDynamicColor"
import ReactEcharts from "echarts-for-react"

import { Col, CardBody } from "reactstrap"

const pieChart = () => {
  const dataColors =
    '["--bs-primary", "--bs-warning", "--bs-danger", "--bs-success"]'
  const PieEChartColors = getChartColorsArray(dataColors)
  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: [
        "New Requests",
        "Work in Progress",
        "Waiting For Approval",
        "Completed Requests",
      ],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: PieEChartColors,
    series: [
      {
        name: "Total sales",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: [
          { value: 335, name: "New Requests" },
          { value: 310, name: "Work in Progress" },
          { value: 234, name: "Waiting For Approval" },
          { value: 1548, name: "Completed Requests" },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }
  return (
    <CardBody>
      <Col>
        <ReactEcharts style={{ height: "350px" }} option={options} />
      </Col>
    </CardBody>
  )
}

export default pieChart
