import {
    GET_CITIES_FAIL,
    GET_CITIES_SUCCESS,
    GET_COUNTRIES_FAIL,
    GET_COUNTRIES_SUCCESS,
    GET_STATES_FAIL,
    GET_STATES_SUCCESS,
  } from "./actionTypes";
  
  const INIT_STATE = {
    cities: [],
    states: [],
    countries: [],
    error: {},
    loading: true,
  };
  
  const common = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CITIES_SUCCESS:
        return {
          ...state,
          cities: action.payload,
          loading: false, // Set loading to false on success
        };
  
      case GET_CITIES_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false, // Set loading to false on failure
        };
  
      case GET_STATES_SUCCESS:
        return {
          ...state,
          states: action.payload,
          loading: false, // Set loading to false on success
        };
  
      case GET_STATES_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false, // Set loading to false on failure
        };
  
      case GET_COUNTRIES_SUCCESS:
        return {
          ...state,
          countries: action.payload,
          loading: false, // Set loading to false on success
        };
  
      case GET_COUNTRIES_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false, // Set loading to false on failure
        };
  
      default:
        return state;
    }
  };
  
  export default common;
  