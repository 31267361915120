/* PROJECTUNITS */
export const GET_PROJECTUNITS = "GET_PROJECTUNITS"
export const GET_PROJECTUNITS_SUCCESS = "GET_PROJECTUNITS_SUCCESS"
export const GET_PROJECTUNITS_FAIL = "GET_PROJECTUNITS_FAIL"

/* PROJECTUNITS DETAIL*/
export const GET_PROJECTUNIT_DETAIL = "GET_PROJECTUNIT_DETAIL"
export const GET_PROJECTUNIT_DETAIL_SUCCESS = "GET_PROJECTUNIT_DETAIL_SUCCESS"
export const GET_PROJECTUNIT_DETAIL_FAIL = "GET_PROJECTUNIT_DETAIL_FAIL"

/**
 * add projectUnit
 */
 export const ADD_NEW_PROJECTUNIT = "ADD_NEW_PROJECTUNIT"
 export const ADD_PROJECTUNIT_SUCCESS = "ADD_PROJECTUNIT_SUCCESS"
 export const ADD_PROJECTUNIT_FAIL = "ADD_PROJECTUNIT_FAIL"
 
 /**
  * Edit projectUnit
  */
 export const UPDATE_PROJECTUNIT = "UPDATE_PROJECTUNIT"
 export const UPDATE_PROJECTUNIT_SUCCESS = "UPDATE_PROJECTUNIT_SUCCESS"
 export const UPDATE_PROJECTUNIT_FAIL = "UPDATE_PROJECTUNIT_FAIL"
 
 /**
  * Delete projectUnit
  */
 export const DELETE_PROJECTUNIT = "DELETE_PROJECTUNIT"
 export const DELETE_PROJECTUNIT_SUCCESS = "DELETE_PROJECTUNIT_SUCCESS"
 export const DELETE_PROJECTUNIT_FAIL = "DELETE_PROJECTUNIT_FAIL"
