import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
// import UpcomingPayment from "./LatestTickets"
import Carousel from "./Corousel"
import PreviousInvoices from "./PreviousInvoice"
import Requests from "./Requests"
import LatestTickets from "./LatestTickets"

const Dashboard = props => {
  document.title = "Dashboard "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="4">
              {/* <Requests /> */}
              <LatestTickets />
              {/* <PreviousInvoices /> */}
            </Col>

            <Col xl="8">
              <Carousel />

              
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
