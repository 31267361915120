import {
  GET_COMPANY_FILTER,
  GET_COMPANY_FILTER_SUCCESS,
  GET_COMPANY_FILTER_FAIL,
  
  GET_PROJECT_FILTER,
  GET_PROJECT_FILTER_SUCCESS,
  GET_PROJECT_FILTER_FAIL,
 
} from "./actionTypes"
//GET COMPANY FILTER

export const getCompanyFilter = userId => ({
  type: GET_COMPANY_FILTER,
  userId,
})

export const getCompanyFilterSuccess = companyFilter => ({
  type: GET_COMPANY_FILTER_SUCCESS,
  payload: companyFilter,
})

export const getCompanyFilterFail = error => ({
  type: GET_COMPANY_FILTER_FAIL,
  payload: error,
})



export const getProjectFilter = userId => ({
  type: GET_PROJECT_FILTER,
  userId,
})

export const getProjectFilterSuccess = projectFilter => ({
  type: GET_PROJECT_FILTER_SUCCESS,
  payload: projectFilter,
})

export const getProjectFilterFail = error => ({
  type: GET_PROJECT_FILTER_FAIL,
  payload: error,
})
