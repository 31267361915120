import {
  ADD_NEW_USER_FAIL,
  ADD_NEW_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_USERS_LIST_FAIL,
  GET_USERS_LIST_SUCCESS,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_SUCCESS,
  RESET_USER_PASSWORD_FAIL,
  RESET_USER_PASSWORD_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  userList: [],
  userDetails: [],
  addUser: [],
  resetUserPass: [],
  error: {},
  loading: true,
}

const manageUser = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        loading: false,
      }

    case GET_USERS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
      }
    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        addUser: [...state.addUser, action.payload],
      }

    case ADD_NEW_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        addUser: state.addUser.map(addUser =>
          addUser.id.toString() === action.payload.id.toString()
            ? { addUser, ...action.payload }
            : addUser
        ),
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        userList: state.userList.filter(
          userList => userList.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        resetUserPass: state.resetUserPass.map(reset =>
          reset.id.toString() === action.payload.id.toString()
            ? { reset, ...action.payload }
            : reset
        ),
      }

    case RESET_USER_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default manageUser
