import {
  GET_NEW_SERVICE_REQUESTS_SUCCESS,
  GET_NEW_SERVICE_REQUESTS_FAIL,
  GET_WIP_SERVICE_REQUESTS_SUCCESS,
  GET_WIP_SERVICE_REQUESTS_FAIL,
  GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_SUCCESS,
  GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_FAIL,
  GET_COMPLETED_SERVICE_REQUESTS_SUCCESS,
  GET_COMPLETED_SERVICE_REQUESTS_FAIL,
  GET_SERVICE_REQUEST_DETAIL_FAIL,
  GET_SERVICE_REQUEST_DETAIL_SUCCESS,
  UPDATE_SERVICE_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST_FAIL,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  newServiceRequests: [],
  wipServiceRequests: [],
  waitingForApprovalRequests: [],
  completedRequest: [],
  serviceRequestType: [],
  projectUnits: [],
  maintenanceIssues: [],
  clientInvoices: [],
  supportTickets: [],
  materials: [],
  units: [],
  serviceRequestDetail: {},
  error: {},
  loading: true,
}

const serviceRequests = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NEW_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        newServiceRequests: action.payload,
        loading: false,
      }

    case GET_NEW_SERVICE_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_WIP_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        wipServiceRequests: action.payload,
        loading: false,
      }

    case GET_WIP_SERVICE_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        waitingForApprovalRequests: action.payload,
        loading: false,
      }

    case GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPLETED_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        completedRequest: action.payload,
        loading: false,
      }

    case GET_COMPLETED_SERVICE_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        serviceRequestDetail: action.payload,
      }

    case GET_SERVICE_REQUEST_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        newServiceRequests: state.newServiceRequests.map(newServiceRequests =>
          newServiceRequests.id.toString() === action.payload.id.toString()
            ? { newServiceRequests, ...action.payload }
            : newServiceRequests
        ),
      }

    case UPDATE_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      
    case GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        materials: action.payload,
        loading: false,
      }

    case GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        units: action.payload,
        loading: false,
      }

    case GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default serviceRequests
