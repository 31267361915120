/* NEW SERVICE_REQUESTS */
export const GET_NEW_SERVICE_REQUESTS = "GET_NEW_SERVICE_REQUESTS"
export const GET_NEW_SERVICE_REQUESTS_SUCCESS = "GET_NEW_SERVICE_REQUESTS_SUCCESS"
export const GET_NEW_SERVICE_REQUESTS_FAIL = "GET_NEW_SERVICE_REQUESTS_FAIL"

/* WIP SERVICE_REQUESTS */
export const GET_WIP_SERVICE_REQUESTS = "GET_WIP_SERVICE_REQUESTS"
export const GET_WIP_SERVICE_REQUESTS_SUCCESS = "GET_WIP_SERVICE_REQUESTS_SUCCESS"
export const GET_WIP_SERVICE_REQUESTS_FAIL = "GET_WIP_SERVICE_REQUESTS_FAIL"

/* Waiting For Approval SERVICE_REQUESTS */
export const GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS = "GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS"
export const GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_SUCCESS = "GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_SUCCESS"
export const GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_FAIL = "GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS_FAIL"

/* Completed SERVICE_REQUESTS */
export const GET_COMPLETED_SERVICE_REQUESTS = "GET_COMPLETED_SERVICE_REQUESTS"
export const GET_COMPLETED_SERVICE_REQUESTS_SUCCESS = "GET_COMPLETED_SERVICE_REQUESTS_SUCCESS"
export const GET_COMPLETED_SERVICE_REQUESTS_FAIL = "GET_COMPLETED_SERVICE_REQUESTS_FAIL"

/* SERVICE_REQUESTS DETAIL*/
export const GET_SERVICE_REQUEST_DETAIL = "GET_SERVICE_REQUEST_DETAIL"
export const GET_SERVICE_REQUEST_DETAIL_SUCCESS = "GET_SERVICE_REQUEST_DETAIL_SUCCESS"
export const GET_SERVICE_REQUEST_DETAIL_FAIL = "GET_SERVICE_REQUEST_DETAIL_FAIL"

/**
 * Update serviceRequest
 */
export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST"
export const UPDATE_SERVICE_REQUEST_SUCCESS = "UPDATE_SERVICE_REQUEST_SUCCESS"
export const UPDATE_SERVICE_REQUEST_FAIL = "UPDATE_SERVICE_REQUEST_FAIL"

// MATERIAL MASTER

export const GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST = "GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST"
export const GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS = "GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS"
export const GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL = "GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL"

// UNIT MASTER 

export const GET_UNIT_MASTER_FOR_SERVICE_REQUEST = "GET_UNIT_MASTER_FOR_SERVICE_REQUEST"
export const GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS = "GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS"
export const GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL = "GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL"