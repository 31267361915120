import React from "react"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"


const formatNumberWithCommas = number => {
  // If number is null or empty, return it as it is
  if (!number) {
    return number
  }

  // Convert number to string
  let numStr = String(number)

  // Split the string into integer and decimal parts
  let parts = numStr.split(".")

  // Format the integer part with commas
  let integerPart = parts[0]
  let lastThreeDigits = integerPart.slice(-3) // Get the last three digits
  let remainingDigits = integerPart.slice(0, -3) // Get the remaining digits
  let formattedInteger =
    remainingDigits !== ""
      ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        lastThreeDigits
      : lastThreeDigits

  // Combine integer and decimal parts with a dot
  let formattedNumber = formattedInteger + (parts[1] ? "." + parts[1] : "")

  return formattedNumber
}


export default formatNumberWithCommas
