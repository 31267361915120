import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Label, Modal, Row, Table } from "reactstrap"
import { getLatestInvoice } from "store/actions"
import VipLogo from "../../assets/images/VIP/VLogo.png"
import { createSelector } from "reselect"
const LargeModal = ({ isOpen, toggle }) => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  // const latestInvoiceState = state => state.Dashboard.latestInvoice.billing
  // const latestInvoice = useSelector(latestInvoiceState) || []

  const latestInvoicePreviewState = state => state.Dashboard
  const latestInvoiceProperties = createSelector(
    latestInvoicePreviewState,
    billing => ({
      invoiceDetail: billing?.latestInvoice?.billing,
      customerDetail: billing?.latestInvoice?.billing?.client,
      companyDetail: billing?.latestInvoice?.billing?.company,
    })
  )

  const { invoiceDetail, customerDetail, companyDetail } = useSelector(
    latestInvoiceProperties
  )

  // const loadingState = state => state.serviceRequests.loading
  // const loading = useSelector(loadingState) || []
  // const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    // dispatch(getLatestInvoice(clientId))
  }, [dispatch])

  function convertRupeesToWords(amount) {
    const words1to19 = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ]
    const wordsTens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ]
    const wordsPowersOf10 = ["", "Thousand", "Lakh", "Crore"]

    function convertThreeDigits(num) {
      let words = ""
      let hundred = Math.floor(num / 100)
      let remaining = num % 100

      if (hundred > 0) {
        words += words1to19[hundred] + " Hundred "
      }

      if (remaining !== 0) {
        if (remaining < 20) {
          words += words1to19[remaining]
        } else {
          let tens = Math.floor(remaining / 10)
          let ones = remaining % 10
          words += wordsTens[tens] + " " + words1to19[ones]
        }
      }

      return words.trim()
    }

    if (amount === 0) {
      return "Zero Rupees Only"
    }

    let words = ""
    let count = 0

    while (amount > 0) {
      let group = amount % 1000
      if (group !== 0) {
        let groupWords = convertThreeDigits(group)
        if (groupWords !== "") {
          words = groupWords + " " + wordsPowersOf10[count] + " " + words
        }
      }
      amount = Math.floor(amount / 1000)
      count++
    }

    if (words.trim() === "") {
      return "NA"
    }

    return words.trim() + " Rupees Only"
  }

  // Example usage:
  const amount = 123456789
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          View Invoice
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    {" "}
                    <img
                      src={VipLogo}
                      alt="logo-dark"
                      style={{ height: "80px" }}
                    />
                  </Col>
                  <Col className="mt-2">
                    <h4 style={{ fontSize: "16px" }}>
                      {companyDetail && companyDetail?.name}
                    </h4>
                    <h4 style={{ fontSize: "16px" }}>
                      {companyDetail && companyDetail?.address_line_1}
                    </h4>
                    <h4 style={{ fontSize: "16px" }}>
                      {companyDetail && companyDetail?.address_line_2}
                    </h4>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm="6">
                    <strong> To:</strong>
                    <br />
                    Client Name :{" "}
                    {customerDetail && customerDetail?.customer_name} <br />
                    Address : {customerDetail && customerDetail?.address} <br />
                    GST No : {customerDetail && customerDetail?.gst_no}5 <br />
                    PAN No : {customerDetail && customerDetail?.pan_no}
                    <br />
                    <hr />
                    <React.Fragment>
                      <span> {invoiceDetail?.customer_name}</span> <br />
                    </React.Fragment>
                  </Col>
                  <Col sm="6" className="text-sm-end">
                    {/* <strong>Shipped To:</strong> */}
                    <br />
                    Invoice Date : {invoiceDetail &&
                      invoiceDetail?.bill_date}{" "}
                    <br />
                    Invoice Number : {invoiceDetail &&
                      invoiceDetail?.bill_no}{" "}
                    <br />
                    GST NO : {invoiceDetail && invoiceDetail?.bill_no} <br />
                    Place of Supply :{" "}
                    {invoiceDetail &&
                      invoiceDetail?.gst_place_of_supply?.state}{" "}
                    <br />
                    <hr />
                  </Col>
                </Row>
                <div className="py-2 ">
                  <h3 className="font-size-15 fw-bold">Bill summary</h3>
                </div>
                <div className="table-responsive">
                  <Table className="table-nowrap">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Invoice Type</th>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Amount per Unit</th>
                        <th className="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetail?.billing_components.map((item, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{item?.invoice_type}</td>
                          <td>{item?.description}</td>
                          <td>{item?.unit}</td>
                          <td>{item?.amt_per_unit}</td>
                          <td className="text-end">{item?.total}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="5" className="text-end">
                          Bill Amount
                        </td>
                        <td className="text-end">
                          {invoiceDetail && invoiceDetail?.subtotal}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="text-end">
                          Discount
                        </td>
                        <td className="text-end">
                          {(invoiceDetail && invoiceDetail?.discount) || "NA"}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="text-end">
                          Taxable Amount
                        </td>
                        <td className="text-end">
                          {invoiceDetail && invoiceDetail?.discounted_subtotal}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="text-end">
                          CGST
                        </td>
                        <td className="text-end">
                          {invoiceDetail && invoiceDetail?.cgst_amount}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="text-end">
                          SGST
                        </td>
                        <td className="text-end">
                          {invoiceDetail && invoiceDetail?.sgst_amount}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="text-end">
                          IGST
                        </td>
                        <td className="text-end">
                          {invoiceDetail && invoiceDetail?.igst_amount}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="border-0 text-end">
                          <strong>Payable Amount</strong>
                        </td>
                        <td className="border-0 text-end">
                          <h4 className="m-0">{invoiceDetail?.all_total}</h4>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-start " style={{ paddingLeft: "10px" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>Invoice Amount in Words</td>
                          <td>:</td>
                          <td>
                            {" "}
                            {convertRupeesToWords(
                              invoiceDetail?.outstanding_amount
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>CGST Amount in Words</td>
                          <td>:</td>
                          <td>
                            {" "}
                            {convertRupeesToWords(invoiceDetail?.cgst_amount)}
                          </td>
                        </tr>
                        <tr>
                          <td>SGST Amount in Words</td>
                          <td>: </td>
                          <td>
                            {" "}
                            {convertRupeesToWords(invoiceDetail?.sgst_amount)}
                          </td>
                        </tr>
                        <tr>
                          <td>IGST Amount in Words</td>
                          <td>:</td>
                          <td>
                            {" "}
                            {convertRupeesToWords(invoiceDetail?.igst_amount)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="text-end">
                    <table style={{ borderCollapse: "collapse" }}>
                      <tbody>
                        <tr>
                          <td
                            className="text-end"
                            style={{
                              fontSize: "20px",
                              padding: "10px",
                              border: "1px solid #ccc",
                              height: "110px",
                              width: "250px",
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td style={{ height: "20px" }}></td>
                        </tr>
                        <tr>
                          <td style={{ height: "20px" }}>
                            {" "}
                            Authorized Signature
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default LargeModal
