/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";

export const GET_LATEST_TICKET = "GET_LATEST_TICKET";
export const GET_LATEST_TICKET_SUCCESS = "GET_LATEST_TICKET_SUCCESS";
export const GET_LATEST_TICKET_FAIL = "GET_LATEST_TICKET_FAIL";

export const GET_PREVIOUS_INVOICES_LIST = "GET_PREVIOUS_INVOICES_LIST";
export const GET_PREVIOUS_INVOICES_LIST_SUCCESS = "GET_PREVIOUS_INVOICES_LIST_SUCCESS";
export const GET_PREVIOUS_INVOICES_LIST_FAIL = "GET_PREVIOUS_INVOICES_LIST_FAIL";

export const GET_SERVICE_REQUEST="GET_SERVICE_REQUEST"
export const GET_SERVICE_REQUEST_SUCCESS="GET_SERVICE_REQUEST_SUCCESS"
export const GET_SERVICE_REQUEST_FAIL="GET_SERVICE_REQUEST_FAIL"