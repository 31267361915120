import React, { useEffect, useState } from "react"
import SimpleBar from "simplebar-react"
// import { activityData } from "common/data"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getServiceRequestDetail } from "store/actions"
import Spinners from "components/Common/Spinner"

const ServiceRequestView = () => {
  const path = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const serviceRequestDetailState = state =>
    state.serviceRequests.serviceRequestDetail.service_requests
  const loadingState = state => state.serviceRequests.loading
  const loading = useSelector(loadingState) || []
  const data = useSelector(serviceRequestDetailState) || []
  console.log(data)
  const [isLoading, setLoading] = useState(loading)
  const [serviceRequestId, setServiceRequestId] = useState()

  const fetchData = () => {
    const serviceRequestId = path.pathname.split("/view-service-requests/")[1]
    setServiceRequestId(serviceRequestId)
    dispatch(getServiceRequestDetail(serviceRequestId))
  }

  useEffect(() => {
    fetchData()
  }, [dispatch, useLocation])

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <div className="page-content ">
          <Container fluid={true}>
            <Row className="justify-content-center">
              <Col lg={12}>
                <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                  <div className="d-flex gap-2 ">
                    <i
                      className="mdi mdi-arrow-left font-size-20"
                      style={{ color: "grey" }}
                      onClick={() => {
                        navigate(-1)
                      }}
                    ></i>
                    Service Request Details
                  </div>
                </CardTitle>

                <Card>
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="me-2">
                        <h5 className="card-title mb-4">
                          Track Request ({data.srq_code})
                        </h5>
                      </div>
                    </div>
                    <SimpleBar className="mt-2" style={{ maxHeight: "280px" }}>
                      <ul className="verti-timeline list-unstyled">
                        <li
                          className={`event-list ${
                            data.status_name ? "active" : ""
                          }`}
                        >
                          <div className="event-timeline-dot">
                            <i
                              className={`bx bxs-right-arrow-circle font-size-18 ${
                                data.status_name && "bx-fade-right"
                              }`}
                            ></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <h5 className="font-size-14">
                                {data.service_type}
                                {`(${data.created_on})`}
                                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                              </h5>
                            </div>
                            <div className="flex-grow-1">
                              {data.status_name}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </SimpleBar>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <h6>Type of Service</h6>
                      </Col>
                      <Col lg={8}>{data && data.service_type}</Col>
                    </Row>
                    <hr></hr>
                    {data && data.issue_name !== null && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>Maintainence Issue</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.issue_name === null
                              ? "NA"
                              : data.issue_name}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data && data.brand_name !== null && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>
                              Select Project Unit In Which You Are Facing Issue
                            </h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.brand_name === null
                              ? "NA"
                              : data.brand_name}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data &&
                      data.prefered_duration_for_user_engagement !== null && (
                        <>
                          <Row>
                            <Col lg={4}>
                              <h6>
                                How soon you want to start new engagement?
                              </h6>
                            </Col>
                            <Col lg={8}>
                              {data &&
                              data.prefered_duration_for_user_engagement ===
                                null
                                ? "NA"
                                : data.prefered_duration_for_user_engagement}
                            </Col>
                          </Row>
                          <hr />
                        </>
                      )}
                    {data && data.project_unit_category !== null && (
                      <>
                        {" "}
                        <Row>
                          <Col lg={4}>
                            <h6>Category</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.project_unit_category === null
                              ? "NA"
                              : data.project_unit_category}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data && data.budget_prefered !== null && (
                      <>
                        {" "}
                        <Row>
                          <Col lg={4}>
                            <h6>Prefered Budget</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.budget_prefered === null
                              ? "NA"
                              : data.budget_prefered}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data && data.preferred_project_id !== null && (
                      <>
                        {" "}
                        <Row>
                          <Col lg={4}>
                            <h6>Preferred Project</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.preferred_project_id === null
                              ? "NA"
                              : data.preferred_project_id}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    <Row>
                      <Col lg={4}>
                        <h6>Task Description</h6>
                      </Col>
                      <Col lg={8}>{data && data.description}</Col>
                    </Row>
                    <hr></hr>
                    {data && data.files?.length > 0 && (
                      <Row>
                        <Col lg={4}>
                          <h6>Supporting Pics / Videos / Document</h6>
                        </Col>
                        <Col lg={8}>
                          {data && data.files?.length > 0 ? (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {data.files.map((file, index) => (
                                <div
                                  key={index}
                                  style={{
                                    width: "200px",
                                    height: "150px",
                                    margin: "5px",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                  }}
                                >
                                  <img
                                    src={file.file_path}
                                    alt={`File ${index}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            "NA"
                          )}
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default ServiceRequestView
