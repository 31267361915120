import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_COMPANY_FILTER,

  GET_PROJECT_FILTER,
} from "./actionTypes"
import {
  getCompanyFilterSuccess,
  getCompanyFilterFail,
 
  getProjectFilterSuccess,
  getProjectFilterFail,
  
} from "./actions"

import {
  getCompanyFilter,
  
  getProjectFilter,
} from "helpers/backend"

function* fetchCompanyFilter({ userId }) {
  try {
    const response = yield call(getCompanyFilter, userId)
    yield put(getCompanyFilterSuccess(response))
  } catch (error) {
    yield put(getCompanyFilterFail(error))
  }
}


function* fetchProjectFilter({ userId }) {
  try {
    const response = yield call(getProjectFilter, userId)
    yield put(getProjectFilterSuccess(response))
  } catch (error) {
    yield put(getProjectFilterFail(error))
  }
}


function* VendorPaymentSaga() {
  yield takeEvery(GET_COMPANY_FILTER, fetchCompanyFilter)

  yield takeEvery(GET_PROJECT_FILTER, fetchProjectFilter)
}

export default VendorPaymentSaga
