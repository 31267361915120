import React, { useState } from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "components/Common/ChartsDynamicColor"

import { Card, Col, Row, CardBody, Nav, NavItem, NavLink } from "reactstrap"

const StatisticsApplicationsChart = ({ seriesData, dataColors }) => {
  var statisticsApplicationColors = getChartColorsArray(dataColors)

  const series = [
    {
      name: "New Requests",
      type: "column",
      data: seriesData.companay || [],
    },
    {
      name: "Work in Progress Requests",
      type: "column",
      data: seriesData.newjobs || [],
    },
    {
      name: "Waiting For Approval Requests",
      type: "area",
      data: seriesData.totaljobs || [],
    },
    {
      name: "Completed Requests",
      type: "line",
      data: seriesData.jobview || [],
    },
  ]
  var options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      offsetY: 10,
    },
    stroke: {
      width: [0, 0, 2, 2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    fill: {
      opacity: [1, 1, 0.1, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: seriesData.label || [],
    colors: statisticsApplicationColors,
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " points"
          }
          return y
        },
      },
    },
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="350"
        className="apex-charts pb-3"
      />
    </React.Fragment>
  )
}

const StatisticsApplications = () => {
  const [duration, setDuration] = useState("year")
  const statistic_data = {
    companay: [30, 48, 28, 74, 39, 87, 54, 36, 50, 87, 84],
    jobview: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
    label: [
      "01/01/2023",
      "02/01/2022",
      "03/01/2021",
      "04/01/2020",
      "05/01/2019",
      "06/01/2018",
      "07/01/2017",
      "08/01/2016",
      "09/01/2015",
      "10/01/2014",
      "11/01/2013",
    ],
    newjobs: [20, 50, 42, 10, 24, 28, 60, 35, 47, 64, 78],
    totaljobs: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
  }

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody>
            <div className="d-sm-flex flex-wrap">
              <h4 className="card-title mb-4">Statistics Applications</h4>
              <div className="ms-auto">
                <Nav pills>
                  <NavItem>
                    <NavLink
                      className={duration === "week" ? "active" : ""}
                      href="#"
                      onClick={() => changeDuration("week")}
                    >
                      Week
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={duration === "month" ? "active" : ""}
                      href="#"
                      onClick={() => changeDuration("month")}
                    >
                      Month
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={duration === "year" ? "active" : ""}
                      href="#"
                      onClick={() => changeDuration("year")}
                    >
                      Year
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <StatisticsApplicationsChart
              seriesData={statistic_data}
              dataColors='["--bs-primary", "--bs-success", "--bs-warning", "--bs-info"]'
              dir="ltr"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default StatisticsApplications
