import React, { useState } from "react"
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Overview from "./Overview"
import DetailsSection from "./DetailsSection"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getServiceRequestTicketDetail } from "store/actions"
import { useLocation } from "react-router-dom"
import SimpleBar from "simplebar-react"

const ticketView = () => {
  document.title = "Ticket View"
  const path = useLocation()
  const dispatch = useDispatch()
  const [ticketId, setTicketId] = useState()

  const ticketViewDetailState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request_ticket
  const ticketData = useSelector(ticketViewDetailState) || []
  const serviceRequestDetailState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request
  const data = useSelector(serviceRequestDetailState) || []

  const ticketLogState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request_ticket_log
  const trackingData = useSelector(ticketLogState) || []

  useEffect(() => {
    const pathName = path.pathname
    const ticketId = pathName.split("/view-ticket/")[1]
    setTicketId(ticketId)
    dispatch(getServiceRequestTicketDetail(ticketId))
  }, [dispatch, path])

  const getStatusColor = status => {
    switch (status) {
      case "Open":
        return "text-secondary" // Primary color
      case "Ticket Created":
        return "text-primary" // Success color
      case "In Progress":
        return "text-warning" // Warning color
      case "Closed":
        return "text-success" // Danger color
      case "Rejected":
        return "text-danger" // Danger color
      default:
        return ""
    }
  }
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Ticket" breadcrumbItem="Ticket Details" />
          {ticketData && ticketData.ticket_current_status_id === 7 && (
            <Col lg={12}>
              <Label></Label>
              <Alert color="success" role="alert" className="text-center">
                Ticked Has Been Closed
              </Alert>
            </Col>
          )}
           {ticketData && ticketData.ticket_current_status_id === 11 && (
            <Col lg={12}>
              <Label></Label>
              <Alert color="danger" role="alert" className="text-center">
                Latest estimate request has been rejected.{" "}
                <b>Reason : {ticketData && ticketData.rejected_description} </b>
                <br></br> Please re-submit estimate request or start work!
              </Alert>
            </Col>
          )}
          {ticketData && ticketData.ticket_current_status_id === 6 && (Number(user.id) === Number(ticketData.vendor_id)) && (
            <Col lg={12}>
              <Label></Label>
              <Alert color="danger" role="alert" className="text-center">
                You have been requested for rework (<b>Reason :</b>{" "}
                {ticketData.rework_description})
              </Alert>
            </Col>
          )}

          <Card>
            <CardBody>
              <div className="text-left ms-4">
                {/* <img src={adobephotoshop} alt="" height="50" className="mx-auto d-block" /> */}
                <h5 className="mt-3 mb-1">Ticket Activity</h5>
                <p className="text-muted mb-3">
                  {ticketData && ticketData.ticket_no}
                </p>
              </div>
              <div className="d-flex align-items-start"></div>
              <SimpleBar className="mt-2" style={{ maxHeight: "280px" }}>
                {trackingData &&
                  trackingData.map((track, index) => (
                    <ul key={index} className="verti-timeline list-unstyled">
                      <li
                        className={`event-list ${
                          track.status_name ? "active" : ""
                        }`}
                        style={{ marginBottom: "20px" }} // Add margin bottom for spacing
                      >
                        <div className="event-timeline-dot">
                          <i
                            className={`bx bxs-right-arrow-circle font-size-18 ${
                              track.status_name && "bx-fade-right"
                            }`}
                          ></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <h5 className="font-size-14">
                              {track.log_message}
                              <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                            </h5>
                          </div>
                          <div
                            className={`flex-grow-1 ${getStatusColor(
                              track.status_name
                            )}`}
                          >
                            {track.status_name} ({track.created_at})
                          </div>
                        </div>
                      </li>
                    </ul>
                  ))}
              </SimpleBar>
            </CardBody>
          </Card>

          <Row>
            <Overview />
            <DetailsSection />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ticketView
