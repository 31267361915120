import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./ticketsListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import { getServiceRequestTicketList } from "store/tickets/actions"
import getStatusColorTicket from "components/Common/getStatusColorTicket"

const ticketsList = () => {
  const [isLoading, setLoading] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const navigate = useNavigate()
  document.title = "Tickets List"

  const dispatch = useDispatch()
  const serviceTicketListState = state => state.ServiceTicket.serviceTicket.data

  const ticketList = useSelector(serviceTicketListState) || []
  console.log(ticketList)
  useEffect(() => {
    if (user) {
      const value = {
        vendorId: user.id,
      }
      dispatch(getServiceRequestTicketList(value))
    }
  }, [user])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Ticket No.",
        accessor: "ticket_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  // to={`/billing/edit-billing-setup/${cellProps.row.original.id}`}
                >
                  {cellProps.row.original.ticket_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "SRQ Code",
        accessor: "srq_code",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                >
                  {cellProps.row.original.srq_code}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project",
        accessor: "project",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },

      // {
      //   Header: "Project Unit",
      //   accessor: "project_unit",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return (
      //       <>
      //         <h5 className="font-size-14 mb-1">
      //           <Link className="text-dark">
      //             {cellProps.row.original.unit_code}
      //           </Link>
      //         </h5>
      //       </>
      //     )
      //   },
      // },
      {
        Header: "Project Manager",
        accessor: "project_manager_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.project_manager_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Ticket Supervisor",
        accessor: "Ticket_Supervisor_Name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.Ticket_Supervisor_Name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Created By",
        accessor: "created_by",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.created_by}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Status",
        accessor: "status_name",
        filterable: true,

        Cell: cellProps => {
          const {
            status_name,
            status,
            assigned_internal_user_id,
            vendor_id,
            service_request_id,
            ticket_supervisor_id,
            ticket_id,
            ticket_current_status_id,
          } = cellProps.row.original

          return (
            <div className="d-flex gap-3">
              <Link
                to={`/tickets/view-ticket/${cellProps.row.original.ticket_id}`}
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleUserClick(userData)
                }}
              >
                <span
                  style={{
                    color: getStatusColorTicket(
                      cellProps.row.original.ticket_current_status_id
                    ),
                  }}
                >
                  {status_name}
                </span>
                <i
                  className="mdi mdi-chevron-right"
                  id="viewtooltip"
                  style={{ fontSize: "18px", color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tickets" breadcrumbItem="Tickets List" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={ticketList}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ticketsList
