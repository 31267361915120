import React from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

const Settings = () => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#F6F6F6" }}>
        <Container fluid={true}>
          <div className="row justify-content-center">
            <div className="col-md-3">
              <CardTitle className="mb-3">
                <div className="d-flex gap-2 mb-3">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate("/dashboard")
                    }}
                  ></i>
                  <h4 className="mt-1">Settings</h4>
                </div>
              </CardTitle>
              <Card>
                <CardBody>
                  <CardTitle className="mb-5">Preferences</CardTitle>
                  <div className="flex-shrink-0 d-flex">
                    <i
                      className="bx bx-laptop mt-1 me-1"
                      id="deletetooltip"
                      style={{ color: "#3366cc", fontSize: "25px" }}
                    />{" "}
                    <div className="me-3">
                      <h5 className="font-size-14 mb-0">Macbook Air 2022</h5>
                      <p>Thane, Maharashtra</p>
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-end me-3">
                        <span>This Device</span>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="flex-shrink-0 d-flex">
                    <i
                      className="bx bx-mobile mt-1 me-1"
                      id="deletetooltip"
                      style={{ color: "#3366cc", fontSize: "25px" }}
                    />{" "}
                    <div className="me-3">
                      <h5 className="font-size-14 mb-0">
                        Ashish's Iphone 14 Pro
                      </h5>
                      <p>Mumbai, Maharashtra</p>
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-end">
                        <Button
                          style={{
                            backgroundColor: "#E6E6E6",
                            color: "black",
                            border: "none",
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "50%",
                          }}
                        >
                          Logout
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-5">Preferences</CardTitle>

                  <div className="flex-shrink-0 d-flex">
                    <div className="me-3">
                      <h5 className="font-size-14 mb-0">Language</h5>
                      <p>English (US)</p>
                    </div>
                    <div className="flex-grow-1 d-flex justify-content-end">
                      <div>
                        <select
                          className="form-select"
                          style={{ backgroundColor: "#E6E6E6", width: "100%" }}
                        >
                          <option>English</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="flex-shrink-0 d-flex">
                    <div className="me-3">
                      <h5 className="font-size-14 mt-1">Helpdesk</h5>
                    </div>
                    <div className="flex-grow-1 ">
                      <div className="text-end me-3">
                        <Link to={"/helpdesk"}>
                          <i
                            className="mdi mdi-chevron-right font-size-18"
                            id="viewtooltip"
                            style={{ fontSize: "20px", color: "grey" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Link to={"/logout"}>
                {" "}
                <Button
                  style={{
                    backgroundColor: "#3882E8",
                    color: "white",
                    border: "none",
                    fontSize: "10px",
                    fontWeight: "500",
                    width: "100%",
                    height: "40px",
                  }}
                >
                  Logout from this device
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Settings
