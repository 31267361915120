import {
  GET_PROJECTUNITS_FAIL,
  GET_PROJECTUNITS_SUCCESS,
  GET_PROJECTUNIT_DETAIL_FAIL,
  GET_PROJECTUNIT_DETAIL_SUCCESS,
  ADD_PROJECTUNIT_SUCCESS,
  ADD_PROJECTUNIT_FAIL,
  UPDATE_PROJECTUNIT_SUCCESS,
  UPDATE_PROJECTUNIT_FAIL,
  DELETE_PROJECTUNIT_SUCCESS,
  DELETE_PROJECTUNIT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  projectUnits: [],
  projectUnitDetail: {},
  error: {},
  loading: true,
}

const projectUnits = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTUNITS_SUCCESS:
      return {
        ...state,
        projectUnits: action.payload,
        loading: false,
      }

    case GET_PROJECTUNITS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_PROJECTUNIT_SUCCESS:
      return {
        ...state,
        projectUnits: [...state.projectUnits, action.payload],
      }

    case ADD_PROJECTUNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECTUNIT_DETAIL_SUCCESS:
      return {
        ...state,
        projectUnitDetail: action.payload,
      }

    case UPDATE_PROJECTUNIT_SUCCESS:
      return {
        ...state,
        projectUnits: state.projectUnits.map(projectUnit =>
          projectUnit.id.toString() === action.payload.id.toString()
            ? { projectUnit, ...action.payload }
            : projectUnit
        ),
      }

    case UPDATE_PROJECTUNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROJECTUNIT_SUCCESS:
      return {
        ...state,
        projectUnits: state.projectUnits.filter(
          projectUnit => projectUnit.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_PROJECTUNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECTUNIT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default projectUnits
