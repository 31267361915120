import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_LATEST_TICKET,
  GET_PREVIOUS_INVOICES_LIST,
  GET_SERVICE_REQUEST,
} from "./actionTypes"
import {
  
  getPreviousInvoicesListSuccess,
  getPreviousInvoicesListFail,
  getServiceRequestSuccess,
  getServiceRequestFail,
  getLatestTicketSuccess,
  getLatestTicketFail,
} from "./actions"

import {
  getLatestTicket,
  getPreviousInvoicesList,
  getServiceRequest,
} from "helpers/backend"

function* fetchLatestInvoice({ vendorId }) {
  try {
    const response = yield call(getLatestTicket, vendorId)
    yield put(getLatestTicketSuccess(response))
  } catch (error) {
    yield put(getLatestTicketFail(error))
  }
}
function* fetchPreviousInvoicesList({ clientId }) {
  try {
    const response = yield call(getPreviousInvoicesList, clientId)
    yield put(getPreviousInvoicesListSuccess(response))
  } catch (error) {
    yield put(getPreviousInvoicesListFail(error))
  }
}
function* fetchServiceRequest({ clientId }) {
  try {
    const response = yield call(getServiceRequest, clientId)
    yield put(getServiceRequestSuccess(response))
  } catch (error) {
    yield put(getServiceRequestFail(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_LATEST_TICKET, fetchLatestInvoice)
  yield takeEvery(GET_PREVIOUS_INVOICES_LIST, fetchPreviousInvoicesList)
  yield takeEvery(GET_SERVICE_REQUEST, fetchServiceRequest)
}

export default dashboardSaga
