import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SERVICE_TICKETS_LIST,
  GET_SERVICE_TICKETS_DETAIL,
} from "./actionTypes"
import {
  getServiceRequestTicketListSuccess,
  getServiceRequestTicketListFail,
  getServiceRequestTicketDetailSuccess,
  getServiceRequestTicketDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getServiceRequestTicketDetail,
  getServiceRequestTicketList,
} from "helpers/backend"

function* fetchServiceRequestTicketList({ userId }) {
  try {
    const response = yield call(getServiceRequestTicketList, userId)
    yield put(getServiceRequestTicketListSuccess(response))
  } catch (error) {
    yield put(getServiceRequestTicketListFail(error))
  }
}

function* fetchServiceRequestTicketDetail({ ticketId }) {
  try {
    const response = yield call(getServiceRequestTicketDetail, ticketId)

    yield put(getServiceRequestTicketDetailSuccess(response))
  } catch (error) {
    yield put(getServiceRequestTicketDetailFail(error))
  }
}

function* serviceTicketSaga() {
  yield takeEvery(GET_SERVICE_TICKETS_LIST, fetchServiceRequestTicketList)
  yield takeEvery(GET_SERVICE_TICKETS_DETAIL, fetchServiceRequestTicketDetail)
}

export default serviceTicketSaga
