import React from "react"
import { Navigate } from "react-router-dom"

import Login from "../VIP PAGES/Authentication/Login"
import ChangePass from "../VIP PAGES/Authentication/changePassword"
import Dashboard from "../VIP PAGES/Dashboard/index"
import VendorProfilePreview from "VIP PAGES/VendorProfilePreview/vendorProfilePreview"
import Logout from "../VIP PAGES/Authentication/Logout"
import MyProfile from "VIP PAGES/MyProfile/myProfile"
import Settings from "VIP PAGES/Settings/settings"
import PendingBillList from "VIP PAGES/Billings&Payments/PendingBills/pendingBillList"
import BillHistoryList from "VIP PAGES/Billings&Payments/BillingHistory/billHistoryList"
import MyProjectUnitsList from "VIP PAGES/MyProjectUnits/myProjectUnitsList"
import ProjectUnitView from "VIP PAGES/MyProjectUnits/projectUnitView"
import Helpdesk from "VIP PAGES/Helpdesk/helpdesk"
import ServiceRequestsDashboard from "VIP PAGES/ServiceRequests/Dashboard/serviceRequestsDashboard"
import NewRequestsList from "VIP PAGES/ServiceRequests/NewRequests/newRequestsList"
import WIPRequestsList from "VIP PAGES/ServiceRequests/WIPRequests/wipRequestsList"
import WaitingForApprovalList from "VIP PAGES/ServiceRequests/WaitingForApprovalRequests/waitingForApprovalRequestsList"
import CompletedRequestsList from "VIP PAGES/ServiceRequests/CompletedRequests/completedRequestsList"
import ServiceRequestView from "VIP PAGES/ServiceRequests/ViewServiceRequest/viewServiceRequest"
import AddNewUser from "VIP PAGES/ManageUsers/AddNewUser/addNewUser"
import AllUsersList from "VIP PAGES/ManageUsers/AllUsers/allUsersList"
import EditUser from "VIP PAGES/ManageUsers/EditUser/editUser"
import ViewUserDetail from "VIP PAGES/ManageUsers/ViewUserDetail/viewUserDetail"
import TicketsList from "VIP PAGES/Tickets/TicketList/ticketsList"
import TicketView from "VIP PAGES/Tickets/TicketView/ticketView"
import Invoice from "VIP PAGES/Billings&Payments/Invoice/invoice"
import ForgetPassword from "VIP PAGES/Authentication/ForgetPassword"
import ProposalList from "VIP PAGES/Proposal/proposalList"
import AddProposal from "VIP PAGES/Proposal/addProposal"
import ProposalView from "VIP PAGES/Proposal/proposalView"
import AddDebitNote from "VIP PAGES/Billings&Payments/DebitNote/addDebitNote"
import DebitNoteList from "VIP PAGES/Billings&Payments/debitNoteList/debitNoteList"
import NotificationList from "VIP PAGES/Notifications/notificationList"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/bills-payments/pending-bills", component: <PendingBillList /> },
  { path: "/bills-payments/bill-history", component: <BillHistoryList /> },
  { path: "/bills-payments/new-invoice", component: <Invoice /> },
  { path: "/bills-payments/debit-note-history", component: <DebitNoteList /> },
  { path: "/project-units", component: <MyProjectUnitsList /> },
  {
    path: "/project-units/project-unit-view/:id",
    component: <ProjectUnitView />,
  },
  { path: "/helpdesk", component: <Helpdesk /> },
  {
    path: "/service-requests/dashboard",
    component: <ServiceRequestsDashboard />,
  },
  {
    path: "/service-requests/new-service-requests",
    component: <NewRequestsList />,
  },
  {
    path: "/service-requests/wip-service-requests",
    component: <WIPRequestsList />,
  },
  {
    path: "/service-requests/waiting-for-approval-service-requests",
    component: <WaitingForApprovalList />,
  },
  {
    path: "/service-requests/completed-service-requests",
    component: <CompletedRequestsList />,
  },
  {
    path: "/service-requests/view-service-requests/:id",
    component: <ServiceRequestView />,
  },
  { path: "/manage-users/add-new-user", component: <AddNewUser /> },
  { path: "/manage-users/edit-user/:id", component: <EditUser /> },
  { path: "/manage-users/all-users", component: <AllUsersList /> },
  { path: "/manage-users/view-user-detail/:id", component: <ViewUserDetail /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  //TICKETS

  {
    path: "/tickets/tickets-list",
    component: <TicketsList />,
  },
  {
    path: "/tickets/view-ticket/:id",
    component: <TicketView />,
  },
  
  //proposal
  {
    path: "/proposals/all-proposals",
    component: <ProposalList />,
  },
  {
    path: "proposals/add-new-proposal",
    component: <AddProposal />,
  },
  {
    path: "/view-vendor-proposal/:id",
    component: <ProposalView />,
  },

  //notifications
{
  path: "/notifications",
  component: <NotificationList/>,
},
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/change-password", component: <ChangePass /> },
  { path: "/forget-password", component: <ForgetPassword /> },
  { path: "/vendor-profile-preview", component: <VendorProfilePreview /> },
  { path: "/myProfile", component: <MyProfile /> },
  { path: "/settings", component: <Settings /> },
]

export { authProtectedRoutes, publicRoutes }
