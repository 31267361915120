import React, { useEffect, useState } from "react"
import { Col, Button, Row, Input, Label } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"

import {
  getBillHistoryList,
  getCompanyFilter,
  getPendingBillList,
  getProjectFilter,
  getProposalsType,
} from "store/actions"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  const projectstate = state => state.VendorPaymentList.projectFilter.data
  const projectList = useSelector(projectstate) || []
  const companystate = state => state.VendorPaymentList.companyFilter.data
  const companyList = useSelector(companystate) || []

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [project, setProject] = useState("")
  const [good_services, setGoodsServices] = useState("")
  const [approval, setApproval] = useState("")
  const [status, setStatus] = useState("")

  const proposalState = state => state.proposals.proposalType.data
  const proposalTypeList = useSelector(proposalState) || []

  const dispatch = useDispatch()

  const handleSelectProject = ele => {
    const value = ele.value
    setProject(value)
  }
  const handleSelectGoodsServices = ele => {
    const value = ele.value
    setGoodsServices(value)
  }
  const handleSelectApprovalStatus = ele => {
    const value = ele.value
    setApproval(value)
  }
  const handleSelectStatus = ele => {
    const value = ele.value
    setStatus(value)
  }

  const handleSearch = () => {
    const value = {
      project_id: project,
      approval_status: approval,
      payment_status: status,
      expense_type_id: good_services,
    }
    console.log(value, "value")
    dispatch(getBillHistoryList(value))
  }

  useEffect(() => {
    dispatch(getProjectFilter())
  }, [])
  useEffect(() => {
    if (project) {
      dispatch(getCompanyFilter(project))
    }
  }, [project])

  useEffect(() => {
    dispatch(getProposalsType())
  }, [dispatch])

  return (
    <React.Fragment>
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleSelectProject(e.target)}
        >
          <option value="">Select Project</option>
          {(projectList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.project_name}
            </option>
          ))}
        </select>
      </Col>
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Goods/Services Category"
          onChange={e => handleSelectGoodsServices(e.target)}
        >
          <option value="">Select Goods & Services Category</option>
          {(proposalTypeList || []).map(proposal => (
            <option key={proposal.id} value={proposal.id}>
              {proposal.proposal_type}
            </option>
          ))}
        </select>
      </Col>
      <Col xxl={2} lg={1}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          onChange={e => handleSelectApprovalStatus(e.target)}
        >
          <option value="">Invoice Verify Status </option>
          <option value="">All</option>
          <option value="1">Approved</option>
          <option value="2">Rejected</option>
          <option value="0">Pending</option>
        </select>
      </Col>
      <Col xxl={2} lg={1}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          onChange={e => handleSelectStatus(e.target)}
        >
          <option value="">Payment Status</option>
          <option value="">All</option>
          <option value="1">Paid</option>
          <option value="0">Unpaid</option>
        </select>
      </Col>

      <Col xxl={1} lg={1} className="mb -3">
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default JobListGlobalFilter
