import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"
import { forgotPassword } from "../../helpers/backend"
import Swal from "sweetalert2"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  let loadingSwal;
  try {
    // Display loading Swal
    loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    // Make the API call
    const response = yield call(
      forgotPassword,
      {
        email_address: user.email,
      },
      "/forgot-password"
    );

    // Close loading Swal
    loadingSwal.close();

    // Handle successful response
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          "Reset link has been sent to your registered email."
        )
      );
    } else {
      const errorMessage = response.message || "An error occurred.";
      yield put(userForgetPasswordSuccess(errorMessage));
    }

  } catch (error) {
    // Close loading Swal in case of error
    if (loadingSwal) loadingSwal.close();
    
    // Extract error message safely
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    console.log(errorMessage);

    // Dispatch error action
    yield put(userForgetPasswordError(errorMessage));
  }
}


export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
