import React from "react"
import IndividualInterval from "./CarouselTypes/individualInterval"

const Carousel = () => {
  return (
    <React.Fragment>
      <IndividualInterval />
    </React.Fragment>
  )
}

export default Carousel
