import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getProjectUnitDetail, getProposalDetail } from "store/actions"

const ProposalView = () => {
  const path = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const projectUnitDetailState = state =>
    state.proposals.proposalDetail.proposal
  const data = useSelector(projectUnitDetailState) || []
  const [projectUnitId, setProjectUnitId] = useState()

  const fetchData = () => {
    const projectUnitId = path.pathname.split("/view-vendor-proposal/")[1]
    setProjectUnitId(projectUnitId)
    dispatch(getProposalDetail(projectUnitId))
  }

  useEffect(() => {
    fetchData()
  }, [dispatch, useLocation])

  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={8}>
              <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 ">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  Proposal Details
                </div>
              </CardTitle>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row className="mb-3">
                        <Col lg={4}>
                          <Label className="form-label ">Project</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.project_name}
                          </p>
                        </Col>
                        <Col lg={4}>
                          <Label className="form-label">Proposal No.</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.proposal_no}
                          </p>
                        </Col>
                        <Col lg={4}>
                          <Label className="form-label ">Proposal Date</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.formatted_proposal_date}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={4}>
                          <Label className="form-label">Proposal Purpose</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.proposal_title}
                          </p>
                        </Col>

                        <Col lg={4}>
                          <Label className="form-label ">Goods & Services Category</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.proposal_type}
                          </p>
                        </Col>
                        <Col lg={4}>
                          <Label className="form-label ">Taxable Amount</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.taxable_amount}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={4}>
                          <Label className="form-label">GST Rate(%)</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.gst_percentage}
                          </p>
                        </Col>

                        <Col lg={4}>
                          <Label className="form-label ">GST Amount</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.tax_amount}
                          </p>
                        </Col>
                        <Col lg={4}>
                          <Label className="form-label">Total Amount</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.invoice_amount}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label className="form-label">Status</Label>
                          <p
                            style={{
                              fontWeight: 500,
                              color:
                                data.proposal_status === 0
                                  ? "blue"
                                  : data.proposal_status === 1
                                  ? "green"
                                  : data.proposal_status === 2
                                  ? "red"
                                  : data.proposal_status === 3
                                  ? "orange"
                                  : "black",
                            }}
                          >
                            {data.proposal_status === 0
                              ? "Submitted"
                              : data.proposal_status === 1
                              ? "Approved"
                              : data.proposal_status === 2
                              ? "Rejected"
                              : data.proposal_status === 3
                              ? "Billed"
                              : "NA"}
                          </p>
                        </Col>
                        {data?.invoice_no && (
                          <Col lg={4}>
                            <Label className="form-label">
                              Tagged Invoice No
                            </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.invoice_no}
                            </p>
                          </Col>
                        )}
                        {data.proposal_status === 2 && (
                          <Col lg={4}>
                            <Label className="form-label">
                              Rejected Reason
                            </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.reject_reason}
                            </p>
                          </Col>
                        )}

                        <Col lg={4}>
                          <button
                            className=" btn btn-warning  "
                            onClick={() => {
                              const proposalFilePath = data.proposal_file_path
                              if (proposalFilePath) {
                                window.open(proposalFilePath, "_blank")
                              }
                            }}
                          >
                            View Proposal
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProposalView
