import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./allUsersListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import { deleteUser, getUsersList } from "store/actions"

const AllUsersList = () => {
  const navigate = useNavigate()
  document.title = "All Employees List"

  const dispatch = useDispatch()
  const manageUserState = state => state.manageUser.userList.data

  const data = useSelector(manageUserState) || []

  const loadingState = state => state.manageUser.loading
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)
  useEffect(() => {
    dispatch(getUsersList())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.firstname}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.lastname}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Email Id",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.email_id}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Phone no.",
        accessor: "mobile_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.mobile_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Username",
        accessor: "login_username",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.login_username}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  className="font-size-16 text-muted"
                  role="button"
                >
                  <i
                    className="mdi mdi-dots-vertical"
                    style={{ fontSize: "18px", color: "gray" }}
                  ></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link
                    to={`/manage-users/edit-user/${cellProps.row.original.id}`}
                  >
                    <DropdownItem href="#">Edit</DropdownItem>{" "}
                  </Link>

                  <Link>
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        const users = cellProps.row.original
                        onClickDelete(users)
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Link
                to={`/manage-users/view-user-detail/${cellProps.row.original.id}`}
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleUserClick(userData)
                }}
              >
                <i
                  className="mdi mdi-chevron-right font-size-18"
                  id="edittooltip"
                  style={{ fontSize: "18px", color: "gray" }}
                />
                <UncontrolledTooltip placement="bottom" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ user: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.user.id) {
      dispatch(deleteUser(deleteId.user.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/manage-users/all-users")
    dispatch(getUsersList())
  }

  const handleUserClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Manage Employee" breadcrumbItem="All Employees" />

          <Row>
            {/* {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : ( */}
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isShowingPageLength={true}
                    isAddUserList={true}
                    iscustomPageSizeOptions={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    tableClass="table align-middle table-nowrap table-hover"
                    theadClass="table-light"
                    paginationDiv="col-sm-12 col-md-7"
                    pagination="pagination pagination-rounded justify-content-end mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
            {/* )} */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllUsersList
