import {
  GET_VENDOR_DETAIL,
  GET_VENDOR_DETAIL_SUCCESS,
  GET_VENDOR_DETAIL_FAIL,
} from "./actionTypes"

export const getVendorDetail = vendorId => ({
  type: GET_VENDOR_DETAIL,
  vendorId,
})

export const getVendorDetailSuccess = vendorDetail => ({
  type: GET_VENDOR_DETAIL_SUCCESS,
  payload: vendorDetail,
})

export const getVendorDetailFail = error => ({
  type: GET_VENDOR_DETAIL_FAIL,
  payload: error,
})
