import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Label } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getLatestInvoice, getLatestTicket } from "store/dashboard/actions"
import LargeModal from "./viewInvoiceModal"

const LatestTickets = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  const latestTicketState = state => state.Dashboard.latestTicket.data
  const latestTicket = useSelector(latestTicketState) || []


  useEffect(() => {
    dispatch(getLatestTicket(clientId))
  }, [dispatch])

  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }
  return (
    <React.Fragment>
      <LargeModal isOpen={modalLarge} toggle={toggleModalLarge} />
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            <h5>Latest Ticket</h5>
          </CardTitle>
          <Row>
            <Col>
            <p>Ticket : {latestTicket && latestTicket?.ticket_no}</p>
            </Col>
            <Col >
            <p>Status : {latestTicket && latestTicket?.status_name}</p>

            </Col>
          </Row>
          <Row>
            <Col>
            <p>Project Name : {latestTicket && latestTicket?.project_name}</p>
            </Col>
            <Col >
            <p>Project Manager : {latestTicket && latestTicket?.project_manager_name}</p>

            </Col>
          </Row>
          <Row>
            <Col>
            <p>Created by: {latestTicket && latestTicket?.created_by}</p>
            </Col>
            <Col >
            <p>Ticket Supervisor : {latestTicket && latestTicket?.Ticket_Supervisor_Name}</p>

            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default LatestTickets
