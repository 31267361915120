import React from "react"

import { Card, Col, CardBody } from "reactstrap"

const typeOfRequest = () => {
  return (
    <React.Fragment>
      {/* {(blodStatsData || []).map((stat, index) => ( */}
      {/* <Col lg={4} key={index}> */}
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="me-3">
                <p className="text-muted mb-2">Total Requests</p>
                <h5 className="mb-0">100</h5>
              </div>
              <div className="avatar-sm ms-auto">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="mdi mdi-room-service-outline"></i>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="me-3">
                <p className="text-muted mb-2">Work In Progress Requests</p>
                <h5 className="mb-0">100</h5>
              </div>
              <div className="avatar-sm ms-auto">
                <div className="avatar-title bg-light rounded-circle text-warning font-size-20">
                  <i className="mdi mdi-room-service-outline"></i>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="me-3">
                <p className="text-muted mb-2">Waiting For Approval Requests</p>
                <h5 className="mb-0">100</h5>
              </div>
              <div className="avatar-sm ms-auto">
                <div className="avatar-title bg-light rounded-circle text-danger font-size-20">
                  <i className="mdi mdi-room-service-outline"></i>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="me-3">
                <p className="text-muted mb-2">Completed Requests</p>
                <h5 className="mb-0">100</h5>
              </div>
              <div className="avatar-sm ms-auto">
                <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                  <i className="mdi mdi-room-service-outline"></i>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      {/* ))} */}
    </React.Fragment>
  )
}

export default typeOfRequest
