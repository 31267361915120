import { call, put, takeEvery } from "redux-saga/effects"
//  Redux States
import { GET_PENDING_BILL_LIST, GET_BILL_HISTORY_LIST, GET_PROJECT_LIST, GET_TICKET_LIST, GET_EXPENSE_LIST, GET_GST_RATE_LIST, GET_PROJECT_COMPANY_DETAIL, ADD_NEW_INVOICE, GET_TICKET_DETAIL, GET_DEBIT_NOTE_LIST } from "./actionTypes"
import {
  getPendingBillListSuccess,
  getPendingBillListFail,
  getBillHistoryListSuccess,
  getBillHistoryListFail,
  getProjectListSuccess,
  getProjectListFail,
  getTicketListSuccess,
  getTicketListFail,
  getExpenseListSuccess,
  getExpenseListFail,
  getGstRateListSuccess,
  getGstRateListFail,
  getProjectCompanyDetailSuccess,
  getProjectCompanyDetailFail,
  addNewInvoiceSuccess,
  addNewInvoiceFail,
  getTicketDetailSuccess,
  getTicketDetailFail,
  getDebitNoteListSuccess,
  getDebitNoteListFail,
} from "./actions"

import { getPendingBillList, getBillHistoryList, getProjectList, getTicketList, getExpenseList, getGstRateList, getProjectCompanyDetail, addNewInvoice, getTicketDetail, getDebitNoteList } from "helpers/backend"
import Swal from "sweetalert2"

function* fetchPendingBillList({ value }) {
  try {
    const response = yield call(getPendingBillList, value)
    yield put(getPendingBillListSuccess(response))
  } catch (error) {
    yield put(getPendingBillListFail(error))
  }
}
function* fetchBillHistoryList({ vendorId }) {
  try {
    const response = yield call(getBillHistoryList, vendorId)
    yield put(getBillHistoryListSuccess(response))
  } catch (error) {
    yield put(getBillHistoryListFail(error))
  }
}
function* fetchProjectList({ vendorId }) {
  try {
    const response = yield call(getProjectList, vendorId)
    yield put(getProjectListSuccess(response))
  } catch (error) {
    yield put(getProjectListFail(error))
  }
}
function* fetchTicketList({ value }) {
  try {
    const response = yield call(getTicketList, value)
    yield put(getTicketListSuccess(response))
  } catch (error) {
    yield put(getTicketListFail(error))
  }
}
function* fetchExpenseList({ vendorId }) {
  try {
    const response = yield call(getExpenseList, vendorId)
    yield put(getExpenseListSuccess(response))
  } catch (error) {
    yield put(getExpenseListFail(error))
  }
}
function* fetchGstRateList({ vendorId }) {
  try {
    const response = yield call(getGstRateList, vendorId)
    yield put(getGstRateListSuccess(response))
  } catch (error) {
    yield put(getGstRateListFail(error))
  }
}
function* fetchProjectCompanyDetail({ projectId }) {
  try {
    const response = yield call(getProjectCompanyDetail, projectId)
    yield put(getProjectCompanyDetailSuccess(response))
  } catch (error) {
    yield put(getProjectCompanyDetailFail(error))
  }
}



function* onAddNewInvoice({ payload: { history, invoice } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addNewInvoice, invoice)
    if (response.status === true) {
      yield put(addNewInvoiceSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Invoice has been Saved.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/bills-payments/bill-history")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    console.error(error)
    yield put(addNewInvoiceFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}
function* fetchTicketDetail({ ticketId }) {
  try {
    const response = yield call(getTicketDetail, ticketId)
    yield put(getTicketDetailSuccess(response))
  } catch (error) {
    yield put(getTicketDetailFail(error))
  }
}

function* fetchDebitNoteList({ value }) {
  try {
    const response = yield call(getDebitNoteList, value)
    yield put(getDebitNoteListSuccess(response))
  } catch (error) {
    yield put(getDebitNoteListFail(error))
  }
}

function* billAndPaymentsSaga() {
  yield takeEvery(GET_PENDING_BILL_LIST, fetchPendingBillList)
  yield takeEvery(GET_BILL_HISTORY_LIST, fetchBillHistoryList)
  yield takeEvery(GET_PROJECT_LIST, fetchProjectList)
  yield takeEvery(GET_TICKET_LIST, fetchTicketList)
  yield takeEvery(GET_EXPENSE_LIST, fetchExpenseList)
  yield takeEvery(GET_GST_RATE_LIST, fetchGstRateList)
  yield takeEvery(GET_PROJECT_COMPANY_DETAIL, fetchProjectCompanyDetail)
  yield takeEvery(ADD_NEW_INVOICE, onAddNewInvoice)
  yield takeEvery(GET_TICKET_DETAIL, fetchTicketDetail)
  yield takeEvery(GET_DEBIT_NOTE_LIST,fetchDebitNoteList)
}

export default billAndPaymentsSaga
