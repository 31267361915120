import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getProjectUnitDetail } from "store/actions"

const ProjectUnitView = () => {
  const path = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const projectUnitDetailState = state =>
    state.projectUnits.projectUnitDetail.project_unit
  const data = useSelector(projectUnitDetailState) || []
  const [projectUnitId, setProjectUnitId] = useState()

  const fetchData = () => {
    const projectUnitId = path.pathname.split("/project-unit-view/")[1]
    setProjectUnitId(projectUnitId)
    dispatch(getProjectUnitDetail(projectUnitId))
  }

  useEffect(() => {
    fetchData()
  }, [dispatch, useLocation])

  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={8}>
          <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
            <div className="d-flex gap-2 ">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              Project Unit Details
            </div>
          </CardTitle>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <Label className="form-label ">Project*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.project_name}
                          </p>
                        </Col>
                        <Col lg={6}>
                          <Label className="form-label">Project Unit*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.unit_code}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <Label className="form-label ">Minimum Monthly Rent*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.minimum_monthly_rent}
                          </p>
                        </Col>
                        <Col lg={6}>
                          <Label className="form-label">Revenue Share(%)*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.revenue_share}
                          </p>
                        </Col>
                      </Row>
                      
                     
                      <Row className="mb-3">
                        <Col lg={6}>
                          <Label className="form-label ">Revenue Share Percentage*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.revenue_share_percentage}
                          </p>
                        </Col>
                        <Col lg={6}>
                          <Label className="form-label">Project Unit*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.rent_payment_schedule}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <Label className="form-label ">Customer Lock In Period*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.customer_lock_in_period}
                          </p>
                        </Col>
                        <Col lg={6}>
                          <Label className="form-label">Company Lock In Period*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.company_lock_in_period}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <Label className="form-label ">Notice Period*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.notice_period}
                          </p>
                        </Col>
                        <Col lg={6}>
                          <Label className="form-label">Security Deposit*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.security_deposit}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <Label className="form-label ">Agreement Start Date*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.agreement_start_date}
                          </p>
                        </Col>
                        <Col lg={6}>
                          <Label className="form-label">Agreement End Date*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.agreement_end_date}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <Label className="form-label ">Rent Escalation(%)*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.rent_escalation_percentage}
                          </p>
                        </Col>
                        <Col lg={6}>
                          <Label className="form-label">Project Unit*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.rent_escalation_period}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <Label className="form-label ">Registration Cost For Agreement On Company*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.registration_cost_for_agreement_on_company}
                          </p>
                        </Col>
                        <Col lg={6}>
                          <Label className="form-label">Other Deposit(Optional)*</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                           None
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectUnitView
