import { GET_CITIES, GET_COUNTRIES, GET_STATES } from "./actionTypes"
import { call, put, takeEvery } from "redux-saga/effects"
import { getCitiesFail, getCitiesSuccess, getCountriesFail, getCountriesSuccess, getStatesFail, getStatesSuccess } from "./actions"
import { getCities, getCountries, getStates } from "helpers/backend"

function* fetchCities({stateId}) {
    try {
      const response = yield call(getCities,stateId)
      
      yield put(getCitiesSuccess(response))
      
    } catch (error) {
      yield put(getCitiesFail(error))
    } 
  }

  function* fetchStates({states}) {
    try {
      const response = yield call(getStates,states)
      
      yield put(getStatesSuccess(response))
      
    } catch (error) {
      yield put(getStatesFail(error))
    } 
  }

  function* fetchCountries() {
    try {
      const response = yield call(getCountries)
      
      yield put(getCountriesSuccess(response))
      
    } catch (error) {
      yield put(getCountriesFail(error))
    } 
  }

  function* commonSaga() {
    yield takeEvery(GET_CITIES, fetchCities)
    yield takeEvery(GET_STATES, fetchStates)
    yield takeEvery(GET_COUNTRIES, fetchCountries)
    
  }
  
  export default commonSaga
  