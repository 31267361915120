import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route, Navigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import { getModules } from "store/actions"
import { useDispatch } from "react-redux"

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [userRole, setUserRole] = useState(user?.user_role)

  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)

  const getComponentByPath = path => {
    const route = authProtectedRoutes.find(route => path === route.path)

    return route ? route.component : null
  }
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}

        {/* {(permission || []).map(item =>
          item.access === 1 ? (
            <Route
              key={item.component_id}
              path={item.page}
              element={
                <Authmiddleware>
                  <Layout>{getComponentByPath(item.page)}</Layout>
                </Authmiddleware>
              }
              exact={true}

            />
          ) : (
            <Route
              key={item.component_id}
              path="*"
              element={
                <NonAuthLayout>
                  <Pages404 />
                </NonAuthLayout>
              }
              exact={true}
            />
          )
        )} */}

        <Route
          path="/"
          element={
            user !== null ? (
              <Authmiddleware>
                <Layout>
                  <Navigate to="/dashboard" />
                </Layout>
              </Authmiddleware>
            ) : (
              <Navigate to="/login" />
            )
          }
        ></Route>
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
