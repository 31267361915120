import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import { Link } from "react-router-dom"

// import VLogo from "../../assets/images/VIP/VLogo.png"
// import logo from "../../assets/images/VipVendors/Logo.png"

import VLogo from "../../assets/images/VipVendors/V1MallShort.jpg"
import logo from "../../assets/images/VipVendors/V1MallCropped.svg"
// import logo from "../../assets/images/VipVendors/V1Fullnobg.png"

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm ">
              <img src={VLogo} alt="" height={30} />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="35"/>
            </span>
          </Link>

          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="19" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
