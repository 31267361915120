import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { updatePass } from "store/actions"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"

// import images

import VLogo from "../../assets/images/VIP/VLogo.png"
// import logo from "../../assets/images/VipVendors/NoBgLogo.png"
import logo from "../../assets/images/VipVendors/V1mallWithoutVinderNoBg.png"


const ChangePass = props => {
  const [passwordShow, setPasswordShow] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  //meta title
  document.title = "Change Password"
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your New Password"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please Enter Your Confirm Password"),
    }),

    onSubmit: async values => {

      try {
        const response = dispatch(updatePass(values, props.router.navigate))
        if (response.status === true) {
          navigate("/dashboard")
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Network Error:", error.message)
      }
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#E6E6E6" }}>
                  <Row>
                    <Col xs={7}>
                      <div className="p-4">
                        <h5> Change Password</h5>
                        <p>Please change your password as your convenience</p>
                      </div>
                    </Col>
                    <Col xs={5}>
                      <div className="p-3">
                        <img src={logo} alt="" height={150} />
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="auth-logo">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <i
                            className="rounded-circle bx bx-key"
                            style={{ fontSize: "30px", color: "#3882E8" }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <div className="mb-3">
                          <Label className="form-label">Set Password</Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Set New password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label className="form-label">
                            Confirm password{" "}
                          </Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="confirm_password"
                              className="form-control"
                              placeholder="Confirm the password"
                              type={passwordShow ? "text" : "password"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirm_password || ""}
                              invalid={
                                validation.touched.confirm_password &&
                                validation.errors.confirm_password
                                  ? true
                                  : false
                              }
                            />
                            <button
                              onClick={() => setPasswordShow(!passwordShow)}
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                          {validation.touched.confirm_password &&
                          validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirm_password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <div className="text-center">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} V1 MALLS </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ChangePass)

ChangePass.propTypes = {
  history: PropTypes.object,
}
