import axios from "axios"
import { del, get, post, postWithFile, put } from "./api"
import * as url from "./url"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

const login = data => post(url.LOGIN, data)
const logout = data => get(url.LOGOUT_USER, data)
const updatePass = user => post(url.UPDATE_PASS, user)
const resetUserPassword = (id, user) =>
  post(`${url.RESET_USER_PASSWORD}/${id}`, user)
export const forgotPassword = user => post(url.FORGET_PASSWORD, user)

// CITY,STATES AND COUNTRY
export const getCities = stateId => get(`${url.GET_CITIES}/${stateId}`)
export const getStates = states => get(`${url.GET_STATES}/${states}`)
export const getCountries = () => get(url.GET_COUNTRIES)

//PROJECT UNITS
export const getProjectUnits = id => get(`${url.GET_PROJECTUNITS}/${id}`)
export const getProjectUnitDetails = id =>
  get(`${url.GET_PROJECTUNIT_DETAIL}/${id}`)
export const addNewProjectUnit = () => post(url.ADD_NEW_PROJECTUNIT)
export const updateProjectUnit = () => post(url.UPDATE_PROJECTUNIT)
export const deleteProjectUnit = () => del(url.DELETE_PROJECTUNIT)
//PROPOSAL
export const getProposals = value => post(`${url.GET_PROPOSALS}`,value)
export const getProposalsType = value => get(`${url.GET_PROPOSALS_TYPE}`)
export const getAcceptedProposal = () => get(`${url.GET_ACCEPTED_PROPOSAL}`)
export const getProposalDetails = id =>
  get(`${url.GET_PROPOSAL_DETAIL}/${id}`)
export const addNewProposal = (proposal) => postWithFile(url.ADD_NEW_PROPOSAL,proposal)
export const updateProposal = () => post(url.UPDATE_PROPOSAL)
export const deleteProposal = () => del(url.DELETE_PROPOSAL)

//SERVICE REQUEST

export const getNewServiceRequests = clientId =>
  get(`${url.GET_NEW_SERVICE_REQUESTS}/${clientId}`)
export const getWipServiceRequests = clientId =>
  get(`${url.GET_WIP_SERVICE_REQUESTS}/${clientId}`)
export const getWaitingForApprovalServiceRequests = clientId =>
  get(`${url.GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS}/${clientId}`)
export const getCompletedServiceRequests = clientId =>
  get(`${url.GET_COMPLETED_SERVICE_REQUESTS}/${clientId}`)
export const getServiceRequestDetails = id =>
  get(`${url.GET_SERVICE_REQUEST_DETAIL}/${id}`)
export const updateServiceRequest = () => post(url.UPDATE_SERVICE_REQUEST)

//BILLS AND PAYMENT
export const getPendingBillList = data =>
  post(`${url.GET_PENDING_BILL_LIST}`, data)
export const getBillHistoryList = data =>
  post(`${url.GET_BILL_HISTORY_LIST}`, data)
export const getProjectList = vendorId => get(`${url.GET_PROJECT_LIST}`)
export const getTicketList = value => post(`${url.GET_TICKET_LIST}`, value)
export const getExpenseList = vendorId => get(`${url.GET_EXPENSE_LIST}`)
export const getGstRateList = vendorId => get(`${url.GET_GST_RATE_LIST}`)
export const getProjectCompanyDetail = projectId =>
  get(`${url.GET_PROJECT_COMPANY_DETAIL}/${projectId}`)
export const addNewInvoice = invoice =>
  postWithFile(url.ADD_NEW_INVOICE, invoice)
export const getTicketDetail = ticketId =>
  get(`${url.GET_TICKET_DETAIL}/${ticketId}`)
export const getDebitNoteList = data =>
  post(`${url.GET_DEBIT_NOTE_LIST}`, data)

export const getLatestTicket = vendorId =>
  get(`${url.GET_LATEST_TICKET}/${vendorId}`)
export const getPreviousInvoicesList = clientId =>
  get(`${url.GET_PREVIOUS_INVOICES_LIST}/${clientId}`)
export const getServiceRequest = clientId =>
  get(`${url.GET_SERVICE_REQUEST}/${clientId}`)

export const getVendorDetail = vendorId =>
  get(`${url.GET_VENDOR_DETAIL}/${vendorId}`)

export const getUsersList = () => get(`${url.GET_USERS_LIST}`)
export const getUserDetail = id => get(`${url.GET_USER_DETAIL}/${id}`)
export const addNewUser = user => postWithFile(`${url.ADD_NEW_USER}`, user)
export const updateUser = (id, user) =>
  postWithFile(`${url.UPDATE_USER}/${id}`, user)
export const deleteUser = id =>
  del(`${url.DELETE_USER}/${id}`, { headers: { id } })

// service request tickets in vendor
//GET SERVICE TICKETS
export const getServiceRequestTicketList = data =>
  post(`${url.GET_SERVICE_TICKETS_LIST}/${data.vendorId}`, data)
export const getServiceRequestTicketDetail = id =>
  get(`${url.GET_SERVICE_TICKETS_DETAIL}/${id}`)

// delete material manpower other cost in SERVICE TICKETS
export const deleteManpower = id =>
  del(`${url.DELETE_MANPOWER}/${id}`, { headers: { id } })
export const deleteMaterial = id =>
  del(`${url.DELETE_MATERIAL}/${id}`, { headers: { id } })
export const deleteOtherCost = id =>
  del(`${url.DELETE_OTHER_COST}/${id}`, { headers: { id } })

export const getMaterialMasterForServiceRequest = () =>
  get(`${url.GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST}`)
export const getUnitMasterForServiceRequest = () =>
  get(`${url.GET_UNIT_MASTER_FOR_SERVICE_REQUEST}`)

//add proposal, material othercost and manpower
export const addMaterialInTicket = (ticketId, data) =>
  postWithFile(`${url.ADD_MATERIAL_IN_TICKET}/${ticketId}`, data)
export const addManpowerInTicket = (ticketId, data) =>
  postWithFile(`${url.ADD_MANPOWER_IN_TICKET}/${ticketId}`, data)
export const addOtherCostInTicket = (ticketId, data) =>
  postWithFile(`${url.ADD_OTHERCOST_IN_TICKET}/${ticketId}`, data)
export const sendForReviewInTicket = (ticketId, data) =>
  postWithFile(`${url.SEND_FOR_REVIEW_IN_TICKET}/${ticketId}`, data)
export const uploadProposal = (ticketId, data) =>
  postWithFile(`${url.UPLOAD_PROPOSAL}/${ticketId}`, data)
export const updateStatusInTicket = (ticketId, data) =>
  post(`${url.UPDATE_STATUS_IN_TICKET}/${ticketId}`, data)
export const sendProgressInTicket = (ticketId, data) =>
  postWithFile(`${url.SEND_PROGRESS_IN_TICKET}`, data)

//vendor payment filter
export const getProjectFilter = id => get(`${url.GET_PROJECT_FILTER}`)
export const getCompanyFilter = id => get(`${url.GET_COMPANY_FILTER}/${id}`)


//notification
export const getAllNotificationList = (data) => post(`${url.GET_ALL_NOTIFICATION_LIST}`,data)
export const isReadNotification = (data) => post(`${url.IS_READ_NOTIFICATION}`,data)
export const deleteNotification = (data) => post(`${url.DELETE_NOTIFICATION}`,data)



export {
  getLoggedInUser,
  isUserAuthenticated,
  login,
  logout,
  updatePass,
  resetUserPassword,
}
