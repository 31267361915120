import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, UPDATE_PASS } from "./actionTypes"
import { apiError, loginSuccess, updatePassSuccess } from "./actions"
import { login, logout, updatePass } from "../../helpers/backend"
import Swal from "sweetalert2"

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(login, {
        login_username: user.login_username,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))

      if (response.status === true) {
        yield put(loginSuccess(response))
        history("/vendor-profile-preview")
      }
    }
  } catch (error) {
    yield put(apiError(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Your work has been saved.",
    })
    console.log(error)
  }
}

function* onUpdatePass({ payload: { user, history } }) {
  try {
    const response = yield call(updatePass, user)
    yield put(updatePassSuccess(response))
    if (response.status === true) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Password Has Been Updated Successfully.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/dashboard")
        }
      })
    } else {
      Swal.fire({
        title: "failure",
        icon: "error",
        text: response.message,
      })
    }
  } catch (error) {
    Swal.fire({
      title: "failure",
      icon: "error",
      text: "ERROR",
    })
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(logout, history)
    if (response.status === true) {
      localStorage.removeItem("authUser")
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(UPDATE_PASS, onUpdatePass)
}

export default authSaga
