import {
    GET_PROJECTUNITS,
    GET_PROJECTUNITS_FAIL,
    GET_PROJECTUNITS_SUCCESS,
    GET_PROJECTUNIT_DETAIL,
    GET_PROJECTUNIT_DETAIL_FAIL,
    GET_PROJECTUNIT_DETAIL_SUCCESS,
    ADD_NEW_PROJECTUNIT,
    ADD_PROJECTUNIT_SUCCESS,
    ADD_PROJECTUNIT_FAIL,
    UPDATE_PROJECTUNIT,
    UPDATE_PROJECTUNIT_SUCCESS,
    UPDATE_PROJECTUNIT_FAIL,
    DELETE_PROJECTUNIT,
    DELETE_PROJECTUNIT_SUCCESS,
    DELETE_PROJECTUNIT_FAIL,
    
  } from "./actionTypes"
  
  export const getProjectUnits = (id) => ({
    type: GET_PROJECTUNITS,
    id,
  })
  
  export const getProjectUnitsSuccess = projectUnits => ({
    type: GET_PROJECTUNITS_SUCCESS,
    payload: projectUnits,
  })
  
  export const addNewProjectUnit = projectUnit => ({
    type: ADD_NEW_PROJECTUNIT,
    payload: projectUnit,
  })
  
  export const addProjectUnitSuccess = projectUnit => ({
    type: ADD_PROJECTUNIT_SUCCESS,
    payload: projectUnit,
  })
  
  export const addProjectUnitFail = error => ({
    type: ADD_PROJECTUNIT_FAIL,
    payload: error,
  })
  
  export const updateProjectUnit = (id,projectUnit) => ({
    type: UPDATE_PROJECTUNIT,
    payload: {id,projectUnit},
  })
  
  export const updateProjectUnitSuccess = projectUnit => ({
    type: UPDATE_PROJECTUNIT_SUCCESS,
    payload: projectUnit,
  })
  
  export const updateProjectUnitFail = error => ({
    type: UPDATE_PROJECTUNIT_FAIL,
    payload: error,
  })
  
  export const deleteProjectUnit = projectUnit => ({
    type: DELETE_PROJECTUNIT,
    projectUnit,
  })
  
  export const deleteProjectUnitSuccess = projectUnit => ({
    type: DELETE_PROJECTUNIT_SUCCESS,
    payload: projectUnit,
  })
  
  export const deleteProjectUnitFail = error => ({
    type: DELETE_PROJECTUNIT_FAIL,
    payload: error,
  })
  
  export const getProjectUnitsFail = error => ({
    type: GET_PROJECTUNITS_FAIL,
    payload: error,
  })
  
  export const getProjectUnitDetail = projectUnitId => ({
    type: GET_PROJECTUNIT_DETAIL,
    projectUnitId,
  })
  
  export const getProjectUnitDetailSuccess = projectUnitDetails => ({
    type: GET_PROJECTUNIT_DETAIL_SUCCESS,
    payload: projectUnitDetails,
  })
  
  export const getProjectUnitDetailFail = error => ({
    type: GET_PROJECTUNIT_DETAIL_FAIL,
    payload: error,
  })
  