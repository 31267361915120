
//COMPANY FILTER

export const GET_COMPANY_FILTER = "GET_COMPANY_FILTER"
export const GET_COMPANY_FILTER_SUCCESS = "GET_COMPANY_FILTER_SUCCESS"
export const GET_COMPANY_FILTER_FAIL = "GET_COMPANY_FILTER_FAIL"


//PROJECT FILTER

export const GET_PROJECT_FILTER="GET_PROJECT_FILTER"
export const GET_PROJECT_FILTER_SUCCESS="GET_PROJECT_FILTER_SUCCESS"
export const GET_PROJECT_FILTER_FAIL="GET_PROJECT_FILTER_FAIL"

