import {
  GET_ALL_NOTIFICATION_LIST,
  GET_ALL_NOTIFICATION_LIST_FAIL,
  GET_ALL_NOTIFICATION_LIST_SUCCESS,

} from "./actionTypes"


export const getAllNotificationList = (data) => ({
  type: GET_ALL_NOTIFICATION_LIST,
  data,
})

export const getAllNotificationListSuccess = allCompanies => ({
  type: GET_ALL_NOTIFICATION_LIST_SUCCESS,
  payload: allCompanies,
})

export const getAllNotificationListFail = error => ({
  type: GET_ALL_NOTIFICATION_LIST_FAIL,
  payload: error,
})

