import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PROJECTUNITS,
  GET_PROJECTUNIT_DETAIL,
  ADD_NEW_PROJECTUNIT,
  DELETE_PROJECTUNIT,
  UPDATE_PROJECTUNIT,
} from "./actionTypes"
import {
  getProjectUnitsSuccess,
  getProjectUnitsFail,
  getProjectUnitDetailSuccess,
  getProjectUnitDetailFail,
  addProjectUnitFail,
  addProjectUnitSuccess,
  updateProjectUnitSuccess,
  updateProjectUnitFail,
  deleteProjectUnitSuccess,
  deleteProjectUnitFail,
} from "./actions"

import {
  getProjectUnits,
  getProjectUnitDetails,
  addNewProjectUnit,
  updateProjectUnit,
  deleteProjectUnit,
} from "helpers/backend"

function* fetchProjectUnits({id}) {
  try {
    const response = yield call(getProjectUnits,id)
    yield put(getProjectUnitsSuccess(response))
  } catch (error) {
    yield put(getProjectUnitsFail(error))
  }
}

function* fetchProjectUnitDetail({ projectUnitId }) {
  try {
    const response = yield call(getProjectUnitDetails, projectUnitId)
    yield put(getProjectUnitDetailSuccess(response))
  } catch (error) {
    yield put(getProjectUnitDetailFail(error))
  }
}

function* onUpdateProjectUnit({ payload: { id, projectUnit } }) {
  try {
    const response = yield call(updateProjectUnit, id, projectUnit)
    yield put(updateProjectUnitSuccess(response))
  } catch (error) {
    yield put(updateProjectUnitFail(error))
  }
}

function* onDeleteProjectUnit({ projectUnit }) {
  try {
    const response = yield call(deleteProjectUnit, projectUnit)
    yield put(deleteProjectUnitSuccess(response))
  } catch (error) {
    yield put(deleteProjectUnitFail(error))
  }
}

function* onAddNewProjectUnit({ payload: projectUnit }) {
  try {
    const response = yield call(addNewProjectUnit, projectUnit)
    yield put(addProjectUnitSuccess(response))
  } catch (error) {
    yield put(addProjectUnitFail(error))
  }
}


function* projectUnitsSaga() {
  yield takeEvery(GET_PROJECTUNITS, fetchProjectUnits)
  yield takeEvery(GET_PROJECTUNIT_DETAIL, fetchProjectUnitDetail)
  yield takeEvery(ADD_NEW_PROJECTUNIT, onAddNewProjectUnit)
  yield takeEvery(UPDATE_PROJECTUNIT, onUpdateProjectUnit)
  yield takeEvery(DELETE_PROJECTUNIT, onDeleteProjectUnit)
}

export default projectUnitsSaga
