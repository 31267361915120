import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"
//  Redux States
import {
  GET_NEW_SERVICE_REQUESTS,
  GET_WIP_SERVICE_REQUESTS,
  GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS,
  GET_COMPLETED_SERVICE_REQUESTS,
  GET_SERVICE_REQUEST_DETAIL,
  UPDATE_SERVICE_REQUEST,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST,
} from "./actionTypes"
import {
  getNewServiceRequestsSuccess,
  getNewServiceRequestsFail,
  getWipServiceRequestsSuccess,
  getWipServiceRequestsFail,
  getWaitingForApprovalServiceRequestsSuccess,
  getWaitingForApprovalServiceRequestsFail,
  getCompletedServiceRequestsSuccess,
  getCompletedServiceRequestsFail,
  getServiceRequestDetailSuccess,
  getServiceRequestDetailFail,
  updateServiceRequestSuccess,
  updateServiceRequestFail,
  getUnitMasterForServiceRequestSuccess,
  getUnitMasterForServiceRequestFail,
  getMaterialMasterForServiceRequestFail,
  getMaterialMasterForServiceRequestSuccess,
} from "./actions"

import {
  getNewServiceRequests,
  getWipServiceRequests,
  getWaitingForApprovalServiceRequests,
  getCompletedServiceRequests,
  getServiceRequestDetails,
  updateServiceRequest,
  getMaterialMasterForServiceRequest,
  getUnitMasterForServiceRequest,
} from "helpers/backend"

function* fetchNewServiceRequests({ id }) {
  try {
    const response = yield call(getNewServiceRequests, id)
    yield put(getNewServiceRequestsSuccess(response))
  } catch (error) {
    yield put(getNewServiceRequestsFail(error))
  }
}
function* fetchWipServiceRequests({ id }) {
  try {
    const response = yield call(getWipServiceRequests, id)
    yield put(getWipServiceRequestsSuccess(response))
  } catch (error) {
    yield put(getWipServiceRequestsFail(error))
  }
}
function* fetchWaitingForApprovalServiceRequests({ id }) {
  try {
    const response = yield call(getWaitingForApprovalServiceRequests, id)
    yield put(getWaitingForApprovalServiceRequestsSuccess(response))
  } catch (error) {
    yield put(getWaitingForApprovalServiceRequestsFail(error))
  }
}
function* fetchCompletedServiceRequests({ id }) {
  try {
    const response = yield call(getCompletedServiceRequests, id)
    yield put(getCompletedServiceRequestsSuccess(response))
  } catch (error) {
    yield put(getCompletedServiceRequestsFail(error))
  }
}

function* fetchServiceRequestDetail({ serviceRequestId }) {
  try {
    const response = yield call(getServiceRequestDetails, serviceRequestId)
    yield put(getServiceRequestDetailSuccess(response))
  } catch (error) {
    yield put(getServiceRequestDetailFail(error))
  }
}

function* onUpdateServiceRequest({ payload: { id, serviceRequest } }) {
  try {
    const response = yield call(updateServiceRequest, id, serviceRequest)
    yield put(updateServiceRequestSuccess(response))
  } catch (error) {
    yield put(updateServiceRequestFail(error))
  }
}


function* fetchMaterialMasterForServiceRequest() {
  try {
    const response = yield call(getMaterialMasterForServiceRequest)
    yield put(getMaterialMasterForServiceRequestSuccess(response))
  } catch (error) {
    yield put(getMaterialMasterForServiceRequestFail(error))
  }
}

function* fetchUnitMasterForServiceRequest() {
  try {
    const response = yield call(getUnitMasterForServiceRequest)
    yield put(getUnitMasterForServiceRequestSuccess(response))
  } catch (error) {
    yield put(getUnitMasterForServiceRequestFail(error))
  }
}
function* serviceRequestsSaga() {
  yield takeEvery(GET_NEW_SERVICE_REQUESTS, fetchNewServiceRequests)
  yield takeEvery(GET_WIP_SERVICE_REQUESTS, fetchWipServiceRequests)
  yield takeEvery(GET_WAITING_FOR_APPROVAL_SERVICE_REQUESTS, fetchWaitingForApprovalServiceRequests)
  yield takeEvery(GET_COMPLETED_SERVICE_REQUESTS, fetchCompletedServiceRequests)
  yield takeEvery(GET_SERVICE_REQUEST_DETAIL, fetchServiceRequestDetail)
  yield takeEvery(UPDATE_SERVICE_REQUEST, onUpdateServiceRequest)
  yield takeEvery(
    GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST,
    fetchMaterialMasterForServiceRequest
  )
  yield takeEvery(
    GET_UNIT_MASTER_FOR_SERVICE_REQUEST,
    fetchUnitMasterForServiceRequest
  )
}

export default serviceRequestsSaga
