import {
  GET_PENDING_BILL_LIST_SUCCESS,
  GET_PENDING_BILL_LIST_FAIL,
  GET_BILL_HISTORY_LIST_SUCCESS,
  GET_BILL_HISTORY_LIST_FAIL,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_FAIL,
  GET_TICKET_LIST_SUCCESS,
  GET_TICKET_LIST_FAIL,
  GET_EXPENSE_LIST_SUCCESS,
  GET_EXPENSE_LIST_FAIL,
  GET_GST_RATE_LIST_SUCCESS,
  GET_GST_RATE_LIST_FAIL,
  GET_PROJECT_COMPANY_DETAIL_SUCCESS,
  GET_PROJECT_COMPANY_DETAIL_FAIL,
  ADD_NEW_INVOICE_FAIL,
  ADD_NEW_INVOICE_SUCCESS,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_DETAIL_FAIL,
  GET_DEBIT_NOTE_LIST_SUCCESS,
  GET_DEBIT_NOTE_LIST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  pendingBillList: [],
  debitNoteList: [],
  addNewInvoice: [],
  billHistoryList: [],
  projectCompanyDetail: {},
  projectList: [],
  ticketList: [],
  expenseList: [],
  gstRateList: [],
  ticketDetail: {},
  error: {},
  loading: true,
}

const billAndPayments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PENDING_BILL_LIST_SUCCESS:
      return {
        ...state,
        pendingBillList: action.payload,
        loading: false,
      }

    case GET_PENDING_BILL_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILL_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        billHistoryList: action.payload,
        loading: false,
      }

    case GET_BILL_HISTORY_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // --------------
    case GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectList: action.payload,
        loading: false,
      }

    case GET_PROJECT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TICKET_LIST_SUCCESS:
      return {
        ...state,
        ticketList: action.payload,
        loading: false,
      }

    case GET_TICKET_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_EXPENSE_LIST_SUCCESS:
      return {
        ...state,
        expenseList: action.payload,
        loading: false,
      }

    case GET_EXPENSE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_GST_RATE_LIST_SUCCESS:
      return {
        ...state,
        gstRateList: action.payload,
        loading: false,
      }

    case GET_GST_RATE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        projectCompanyDetail: action.payload,
        loading: false,
      }

    case GET_PROJECT_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_INVOICE_SUCCESS:
      return {
        ...state,
        addNewInvoice: [...state.addNewInvoice, action.payload],
      }

    case ADD_NEW_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TICKET_DETAIL_SUCCESS:
      return {
        ...state,
        ticketDetail: action.payload,
        loading: false,
      }

    case GET_TICKET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_DEBIT_NOTE_LIST_SUCCESS:
      return {
        ...state,
        debitNoteList: action.payload,
        loading: false,
      }

    case GET_DEBIT_NOTE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default billAndPayments
