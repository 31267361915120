import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PROPOSALS,
  GET_PROPOSAL_DETAIL,
  ADD_NEW_PROPOSAL,
  DELETE_PROPOSAL,
  UPDATE_PROPOSAL,
  GET_PROPOSALS_TYPE,
} from "./actionTypes"
import {
  getProposalsSuccess,
  getProposalsFail,
  getProposalDetailSuccess,
  getProposalDetailFail,
  addProposalFail,
  addProposalSuccess,
  updateProposalSuccess,
  updateProposalFail,
  deleteProposalSuccess,
  deleteProposalFail,
  getProposalsTypeSuccess,
  getProposalsTypeFail,
} from "./actions"

import {
  getProposals,
  getProposalDetails,
  addNewProposal,
  updateProposal,
  deleteProposal,
  getProposalsType,
} from "helpers/backend"
import Swal from "sweetalert2"

function* fetchProposals({value}) {
  try {
    const response = yield call(getProposals,value)
    yield put(getProposalsSuccess(response))
  } catch (error) {
    yield put(getProposalsFail(error))
  }
}


function* fetchProposalDetail({ proposalId }) {
  try {
    const response = yield call(getProposalDetails, proposalId)
    yield put(getProposalDetailSuccess(response))
  } catch (error) {
    yield put(getProposalDetailFail(error))
  }
}

function* onUpdateProposal({ payload: { id, proposal } }) {
  try {
    const response = yield call(updateProposal, id, proposal)
    yield put(updateProposalSuccess(response))
  } catch (error) {
    yield put(updateProposalFail(error))
  }
}

function* onDeleteProposal({ proposal }) {
  try {
    const response = yield call(deleteProposal, proposal)
    yield put(deleteProposalSuccess(response))
  } catch (error) {
    yield put(deleteProposalFail(error))
  }
}

function* onAddNewProposal({ payload: {history,proposal} }) {
  
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addNewProposal, proposal)
    loadingSwal.close()
    if (response.status === true) {
      yield put(addProposalSuccess(response))

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Proposal has been Submitted.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/proposals/all-proposals")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    console.error(error)
    yield put(addProposalFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}
function* fetchProposalsType({value}) {
  try {
    const response = yield call(getProposalsType,value)
    yield put(getProposalsTypeSuccess(response))
  } catch (error) {
    yield put(getProposalsTypeFail(error))
  }
}

function* proposalsSaga() {
  yield takeEvery(GET_PROPOSALS, fetchProposals)
  yield takeEvery(GET_PROPOSAL_DETAIL, fetchProposalDetail)
  yield takeEvery(ADD_NEW_PROPOSAL, onAddNewProposal)
  yield takeEvery(UPDATE_PROPOSAL, onUpdateProposal)
  yield takeEvery(DELETE_PROPOSAL, onDeleteProposal)
  yield takeEvery(GET_PROPOSALS_TYPE, fetchProposalsType)
}

export default proposalsSaga
