import {
  GET_LATEST_INVOICE_SUCCESS,
  GET_LATEST_INVOICE_FAIL,
  GET_PREVIOUS_INVOICES_LIST_SUCCESS,
  GET_PREVIOUS_INVOICES_LIST_FAIL,
  GET_SERVICE_REQUEST_SUCCESS,
  GET_SERVICE_REQUEST_FAIL,
  GET_LATEST_TICKET_SUCCESS,
  GET_LATEST_TICKET_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  latestInvoice: [],
  latestTicket: [],
  previousInvoicesList: [],
  serviceRequest: [],
  error: {},
  loading: true,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LATEST_TICKET_SUCCESS:
      return {
        ...state,
        latestTicket: action.payload,
        loading: false,
      }

    case GET_LATEST_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PREVIOUS_INVOICES_LIST_SUCCESS:
      return {
        ...state,
        previousInvoicesList: action.payload,
        loading: false,
      }

    case GET_PREVIOUS_INVOICES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        serviceRequest: action.payload,
        loading: false,
      }

    case GET_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Dashboard
