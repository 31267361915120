import {

  GET_SERVICE_TICKETS_DETAIL,
    GET_SERVICE_TICKETS_DETAIL_FAIL,
    GET_SERVICE_TICKETS_DETAIL_SUCCESS,
    GET_SERVICE_TICKETS_LIST,
    GET_SERVICE_TICKETS_LIST_FAIL,
    GET_SERVICE_TICKETS_LIST_SUCCESS,
    
  } from "./actionTypes"
  
  export const getServiceRequestTicketList = (userId) => ({
    type: GET_SERVICE_TICKETS_LIST,
    userId,
  })
  
  export const getServiceRequestTicketListSuccess = userId => ({
    type: GET_SERVICE_TICKETS_LIST_SUCCESS,
    payload: userId,
  })
  
  export const getServiceRequestTicketListFail = error => ({
    type: GET_SERVICE_TICKETS_LIST_FAIL,
    payload: error,
  })

  export const getServiceRequestTicketDetail = ticketId => ({
    type: GET_SERVICE_TICKETS_DETAIL,
    ticketId,
  })
  
  export const getServiceRequestTicketDetailSuccess = tickets => ({
    type: GET_SERVICE_TICKETS_DETAIL_SUCCESS,
    payload: tickets,
  })
  
  export const getServiceRequestTicketDetailFail = error => ({
    type: GET_SERVICE_TICKETS_DETAIL_FAIL,
    payload: error,
  })
  